import React, { useState } from "react";
import "./forgot-password.scss";
import passwordIcon from "./img/forgot.png";
import api from "../../api";
import { Link} from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { login } from "../redux/authGuard/userAuthenticate";
import { ToastContainer, toast } from "react-toastify";
// import { saveUserData } from "../redux/loginRestaurantData/loginRestaurantData";

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  // const [password, setPassword] = useState();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  //Function for Login
  const forgotPassword = async () => {
    let payload = {
      email: email,
    };
    try {
      await api
        .post("api/identity/forgot-password", payload)
        .then(function (response) {
          console.log("sucess respp");
          toast.success(response.data.message);
          //navigate("/reset-password");
          console.log("password data", response.data.data);

          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          toast.error("Error in api call!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <>
      {/* ======= About Section ======= */}
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <br />
            <br />
            <br />
            <div className="shadow card my-5">
              <form className="card-body cardbody-color">
                <div className="text-center">
                  <img
                    src={passwordIcon}
                    className="img-fluid profile-image-pic img-thumbnail my-3"
                    width="200px"
                    alt="profile"
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    type="text"
                    className="form-control"
                    id="Username"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                  />
                </div>

                <div className="text-center">
                  <button
                    onClick={forgotPassword}
                    type="button"
                    className="btn btn-color px-3 mb-3 w-100"
                  >
                    Submit
                  </button>
                  <ToastContainer />
                </div>
                <div
                  id="emailHelp"
                  className="form-text text-center mb-3 text-dark"
                >
                  {" "}
                  <Link to="/login" className="text-dark fw-bold">
                    {" "}
                    Go to login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* End About Section */}
    </>
  );
}
