import React from "react";
import "./our.css";
// import { Link } from "react-router-dom";
import JoinUs from "../joinus";
import { OurPlatformSection } from "../../constants/constant";

export default function OurPlatform() {
  return (
    <>
      {/* <div className='App'>
            <div 
                className="bg about-img"
                // style={{ backgroundImage: "url(img/restaurant-bg.jpg)" }}
                data-aos="fade-up"
                data-aos-delay={150}
            >
                <div className="call">
                    <h1 className='head'>Welcome To Our Platform</h1>
                    <p >Organise your restaurants and make decised table on real time</p>
                    <p className='para'>Sign up now to receive offers and updates!</p>
                    <div className='btn-1'>
                        <Link to="/register"><button className='btn-Res'>Register Now</button></Link>
                    </div>
                </div>
            </div>
        </div> */}

      <section id="hero" className="hero d-flex align-items-center section-bg">
        <div className="container" style={{ textAlign: "center" }}>
          <div className="">
            <div className="">
              <h2 data-aos="fade-up">
                {OurPlatformSection.WELCOME_TO_OUR_PLATFORM}
                <br />
              </h2>
              <p data-aos="fade-up" data-aos-delay="100">
                Organise your restaurants and make decised table on real time.
              </p>
              <p>Sign up now to receive offers and updates!</p>
              <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                {/* <Link to="/register" className="btn-book-a-table">Register With Us</Link> */}
                {/* <a href="#book-a-table" className="btn-book-a-table">Explore Menu</a> */}
                {/* <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
              </div>
            </div>
            {/* <div className="col-lg-5 order-1 order-lg-2 text-center text-lg-start"> */}
            {/* <img src="assets/img/hero-img.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="300"> */}
            {/* </div> */}
          </div>
        </div>
      </section>
      <JoinUs />
    </>
  );
}
