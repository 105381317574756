// src/features/loginRestaurantData.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  isLoggedIn: boolean;
  isProductUser: boolean;
  restaurantCode: string | null;
  restaurantId: string | null;
  userEmail: string | null;
  userId: string | null;
  userName: string | null;
  userRoleCode: string | null;
  userRoleId: string | null;
}

const initialState: UserState = {
  isLoggedIn: false,
  isProductUser: false,
  restaurantCode: null,
  restaurantId: null,
  userEmail: null,
  userId: null,
  userName: null,
  userRoleCode: null,
  userRoleId: null,
};

const loginRestaurantData = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserData: (state, action: PayloadAction<UserState>) => {
      // Update the state with the received user data
      state.isProductUser = action.payload.isProductUser;
      state.restaurantCode = action.payload.restaurantCode;
      state.restaurantId = action.payload.restaurantId;
      state.userEmail = action.payload.userEmail;
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
      state.userRoleCode = action.payload.userRoleCode;
      state.userRoleId = action.payload.userRoleId;
    },
  },
});

export const { saveUserData } = loginRestaurantData.actions;
export default loginRestaurantData.reducer;
