import { Navigate, Route, Routes } from "react-router-dom";
import SideMenu from "../sidemenu";
import RestaurantList from "../restaurant-list";
import Dish from "../dish";
import Dashboard from "../dashbaord/Dashboard";
import Menu from "../menu";
import Profile from "../Profile";
import Category from "../Category";
import Theme from "../Theme";
import RestaurantTheme from "../RestaurantTheme";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./sidebar.scss";
import BranchMenu from "../BranchMenu";
import Branches from "../Branches";
import { useSelector } from "react-redux";
// import { logout } from "../redux/authGuard/userAuthenticate";
import SideBarHeader from "../header/LoggedInHeader";
import { RootState } from "../redux/store/store";
import RestaurantInfo from "../restaurantInfo";
import UsertList from "../User-List";

import TodaySpecialDish from "../TodaysSpecial";
import RestaurantSpecialDish from "../RestaurantSpecial";
import BuffetMeal from "../BuffetMeal";
import EventMeal from "../EventMeal";
import ComboOffer from "../TodayComboOffer";
import SubscriptionPlan from "../Subscription";




function Sidebar() {
  const [showNav, setShowNav] = useState(true);
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  return (
    <div className={`body-area${showNav ? " body-pd" : ""}`}>
      <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
      <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
        <nav className="sideNav">
          <SideMenu />
        </nav>
      </div>
      <div className="pt-4 pb-4">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/menu"
            element={isAuthenticated ? <Menu /> : <Navigate to="/login" />}
          />
          <Route
            path="/dish"
            element={isAuthenticated ? <Dish /> : <Navigate to="/login" />}
          />
          <Route
            path="/restaurants"
            element={
              isAuthenticated ? <RestaurantList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/restaurants-info"
            element={
              isAuthenticated ? <RestaurantInfo /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/users"
            element={
              isAuthenticated ? <UsertList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/subscription-plan"
            element={
              isAuthenticated ? <SubscriptionPlan /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
          />
          <Route
            path="/theme"
            element={isAuthenticated ? <Theme /> : <Navigate to="/login" />}
          />
          <Route
            path="/restaurant-theme"
            element={
              isAuthenticated ? <RestaurantTheme /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/category"
            element={isAuthenticated ? <Category /> : <Navigate to="/login" />}
          />
          <Route
            path="/branch-menu"
            element={
              isAuthenticated ? <BranchMenu /> : <Navigate to="/login" />
            }
          />
          <Route
          path="/today-combo-offers"
          element={
            isAuthenticated ? <ComboOffer
             /> : <Navigate to="/login" />
          }
        />

          <Route
            path="/todays-special"
            element={
              isAuthenticated ? <TodaySpecialDish /> : <Navigate to="/login" />
            }
          />
          
          <Route
            path="/restaurant-special"
            element={
              isAuthenticated ? <RestaurantSpecialDish /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/buffet-meal"
            element={
              isAuthenticated ? <BuffetMeal /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/event-meal"
            element={
              isAuthenticated ? <EventMeal /> : <Navigate to="/login" />
            }
          />

          <Route
            path="/branches"
            element={isAuthenticated ? <Branches /> : <Navigate to="/login" />}
          />
          {/* <Route path="/menu-management" element={<MenuManagement/>} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default Sidebar;
