import React, { useEffect, useState } from "react";
import "./create-password.scss";
import passwordIcon from "./img/password.png";
import api from "../../api"; // Ensure this is correctly set up to make API calls
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import styles for react-toastify
import SideMenu from "../sidemenu";
import { error } from "console";
import SideBarHeader from "../header/LoggedInHeader";
import { message } from "antd";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();
  const { authCode } = useParams();
  const [showNav, setShowNav] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log("auth code:", authCode);
  }, [authCode]);

  const handleSubmit = async () => {
    try {
      const response = await api.post("/api/identity/reset-password", {
        currentPassword: currentPassword,
        newPassword: newPassword
      });

      if (response.status === 200) {
        message.success("Password changed successfully!");
        setCurrentPassword("");
        setNewPassword("");
        setTimeout(function () {
          navigate("/sidebar");
        }, 6000);
           
      }
    } catch (error) {
      message.error("Failed to change password. Please try again.");
      console.error("Error changing password:", error);
    }
  };

  return (
    <>

      <div>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            
            <div className="container">
              <div className="row">
                <div className="col-md-4 offset-md-4">
                  <br />
                  <br />
                  <br />
                  <div className="shadow card ">
                    <form
                      className="card-body cardbody-color"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="text-center">
                        <img
                          src={passwordIcon}
                          className="img-fluid profile-image-pic img-thumbnail my-3"
                          width="200px"
                          alt="profile"
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <input
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          type={showCurrentPassword ? "text" : "password"}
                          className="form-control"
                          id="currentPassword"
                          placeholder="Current Password"
                        />
                        <i
                          className={`bi ${showCurrentPassword ? 'bi-eye-slash' : 'bi-eye'} password-toggle-icon`}
                          onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                          style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                        ></i>
                      </div>
                      <div className="mb-3 position-relative">
                        <input
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          type={showNewPassword ? "text" : "password"}
                          className="form-control"
                          id="newPassword"
                          placeholder="New Password"
                        />
                        <i
                          className={`bi ${showNewPassword ? 'bi-eye-slash' : 'bi-eye'} password-toggle-icon`}
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                        ></i>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-color px-3 mb-3 w-100"
                        >
                          Submit
                        </button>
                        <ToastContainer />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          
      </div>
    </div >


      <ToastContainer />
    </div >
    </>
  );
};

export default ChangePassword;
