import React, { useEffect, useState } from "react";
import "./create-password.scss";
import passwordIcon from "./img/password.png";
import api from "../../api";
import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { login } from "../redux/authGuard/userAuthenticate";
import { ToastContainer, toast } from "react-toastify";
// import { saveUserData } from "../redux/loginRestaurantData/loginRestaurantData";
import { AxiosRequestConfig } from "axios";

const ResetPassword = () => {
  const [confirmPassword, setConfirmPassword] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { authCode } = useParams();

  useEffect(() => {
    console.log("authhh code " + authCode);
  }, [authCode]);

  //Function for Login
  const createPassword = async () => {
    if (password === confirmPassword) {
      let payload = {
        newPassword: password,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCode,
      };

      const config: AxiosRequestConfig<typeof payload> = {
        headers,
      };
      var url = "/api/identity/create-new-password";
      console.log(
        "urll : " +
          url +
          " auth header " +
          JSON.stringify(headers) +
          " config " +
          JSON.stringify(config)
      );

      try {
        await api
          .post(url, payload, config)
          .then(function (response) {
            console.log("sucess respp");
            toast.error("Password set successful");
            navigate("/login");

            return response.data;
          })
          .catch(function (error) {
            console.log("Show error notification!");
            toast.error("Error in api call!");
            console.log(error);
            return Promise.reject(error);
          });
      } catch (error) {
        console.error("There was an error!", error);
      }
    } else {
      toast.error("Password not matching");
    }
  };
  return (
    <>
      {/* ======= About Section ======= */}
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <br />
            <br />
            <br />
            <div className="shadow card my-5">
              <form className="card-body cardbody-color">
                <div className="text-center">
                  <img
                    src={passwordIcon}
                    className="img-fluid profile-image-pic img-thumbnail  my-3"
                    width="200px"
                    alt="profile"
                  />
                </div>

                <div className="mb-3">
                  <input
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="password"
                    aria-describedby="emailHelp"
                    placeholder="Password"
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={confirmPassword}
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="confirm-password"
                    placeholder="Confirm password"
                  />
                </div>

                <div className="text-center">
                  <button
                    onClick={createPassword}
                    type="button"
                    className="btn btn-color px-3 mb-3 w-100"
                  >
                    Submit
                  </button>
                  <ToastContainer />
                </div>
                {/* <div
                  id="emailHelp"
                  className="form-text text-center mb-3 text-dark"
                >
                    {" "}
                  <Link to="/login" className="text-dark fw-bold">
                    {" "}
                    Go to login 
                  </Link>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* End About Section */}
    </>
  );
};

export default ResetPassword;
