import React, { useEffect, useState } from "react";
import "./modal.scss";

interface UserRole {
  _id: string;
  name: string;
}

interface UserInfo {
  name: string;
  email: string;
  contactNumber: string;
  userRole: string;
}

interface UserProps {
  addUserDetailData: (userInfo: Omit<UserInfo, 'userRole'> & { roleId: string }) => void;
  onClose: () => void;
}

const AddModel: React.FC<UserProps> = ({ addUserDetailData, onClose }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: '',
    email: '',
    contactNumber: '',
    userRole: '',
  });

  const [roles, setRoles] = useState<UserRole[]>([]);
  const [errors, setErrors] = useState<{ name?: string; userRole?: string;  contactNumber?: string }>({});

  useEffect(() => {
    fetch('/api/identity/restaurant-user-role/')
      .then(response => response.json())
      .then(data => setRoles(data.data))
      .catch(error => console.error('Error fetching user roles:', error));
  }, []);

  const validate = (): boolean => {
    const newErrors: { name?: string; userRole?: string; contactNumber?: string } = {};

    if (!userInfo.name.trim()) {
      newErrors.name = "Name is required.";
    } else if (!/^[a-zA-Z\s]+$/.test(userInfo.name.trim())) {
      newErrors.name = "Name can only contain letters and spaces.";
    }
    if (!userInfo.contactNumber.trim()) {
      newErrors.contactNumber = "Contact Number required";
    }
    if (!userInfo.userRole.trim()) {
      newErrors.userRole = "userRole required";
    }

    // const contactNumberPattern = /^[0-9]{10}$/;
    // if (!contactNumberPattern.test(userInfo.contactNumber)) {
    //   newErrors.contactNumber = "Contact number must be a 10-digit number.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    const { userRole, ...rest } = userInfo;
    const formattedUserInfo = { ...rest, roleId: userRole };
    addUserDetailData(formattedUserInfo);
    onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserInfo({
      ...userInfo,
      [name]: value
    });
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRoleId = event.target.value;
    setUserInfo({
      ...userInfo,
      userRole: selectedRoleId
    });
  };

  return (

    <div className="modal"
    tabIndex={-1}
    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">ADD USER DETAILS</h1>
            <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="userName" className="form-label">NAME<span className="text-danger"> *</span></label>
                  <input
                    type="text"
                    placeholder="Enter User Name"
                    name="name"
                    className="form-control"
                    id="userName"
                    value={userInfo.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="userEmail" className="form-label">EMAIL<span className="text-danger"> *</span></label>
                  <input
                    type="email"
                    placeholder="Enter User Email"
                    name="email"
                    className="form-control"
                    id="userEmail"
                    value={userInfo.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="userContact" className="form-label">CONTACT NUMBER<span className="text-danger"> *</span></label>
                  <input
                    type="text"
                    placeholder="Enter User Contact Number"
                    name="contactNumber"
                    className="form-control"
                    id="userContact"
                    value={userInfo.contactNumber}
                    onChange={handleInputChange}
                  />
                  {errors.contactNumber && <div className="text-danger">{errors.contactNumber}</div>}
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="userRole" className="form-label">USER ROLE<span className="text-danger"> *</span></label>
                  <select
                    name="userRole"
                    className="form-control"
                    id="userRole"
                    value={userInfo.userRole}
                    onChange={handleRoleChange}
                  >
                    <option value="" disabled>Select User Role</option>
                    {roles.map(role => (
                      <option key={role._id} value={role._id}>{role.name}</option>
                    ))}
                  </select>
                  {errors.userRole && <div className="text-danger">{errors.userRole}</div>}
                </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}

export default AddModel;
