import { Oval } from "react-loader-spinner";
import "./loader.scss";
function Loader() {
  return (
    <div className="loader-container">
      <Oval
        visible={true}
        height="80"
        width="80"
        color="red"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}
export default Loader;
