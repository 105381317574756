import React from "react";
import { Form, Input } from "antd";
import { FormInstance, FormItemProps, Rule } from "antd/es/form";

interface Props {
  name: string;
  label: string;
  disabled?: boolean; // Corrected type to boolean
  placeholder?: string;
  rules?: Rule[];
  formItemProps?: FormItemProps;
  form?: FormInstance;
}

const { TextArea } = Input;

const TextareaFormItem: React.FC<Props> = ({
  name,
  label,
  placeholder,
  disabled,
  rules = [],
  formItemProps = {},
}) => {
  return (
    <Form.Item name={name} label={label} rules={rules} {...formItemProps}>
      <TextArea
        rows={4}
        style={{ width: "100%" }}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default TextareaFormItem;
