import React, { useState } from "react";
import "./restaurantMenu.scss";
import offer1 from "./img/menu/offer1.png";
import offer2 from "./img/menu/offer2.png";
import restaurant1 from "./img/menu/restaurant1.png";

interface DishCardProps {
  photo: string;
  description: string;
}
interface Testimonial {
  title: string;
  content: string;
  author: string;
}
const RestaurantMenu = () => {
  const [activeTab, setActiveTab] = useState("today");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderOffers = (numOffers, imageUrl) => {
    const offers = [];
    for (let i = 0; i < numOffers; i++) {
      offers.push(
        <div className="offer-item" key={i}>
          <img src={imageUrl} alt={`Offer ${i + 1}`} className="card-img" />
        </div>
      );
    }
    return offers;
  };

  const dishes = [
    {
      photo:
        "https://media.self.com/photos/57d8952946d0cb351c8c50c9/master/w_1600%2Cc_limit/DELICIOUS-1-POT-Lentil-and-Black-Bean-Chili-Smoky-hearty-PROTEIN-and-fiber-packed-vegan-glutenfree-lentils-chili-healthy-recipe2.jpg",
      description: "Tasty, Yummy, Delicious, Delectable, Appetizing",
    },
    {
      photo:
        "https://res.cloudinary.com/rainforest-cruises/images/c_fill,g_auto/f_auto,q_auto/w_1120,h_732,c_fill,g_auto/v1661887113/indian-food/indian-food-1120x732.jpg",
      description: "Nutritious, Satisfying, Savory, Tasty, Yummy",
    },
    {
      photo:
        "https://plus.unsplash.com/premium_photo-1673590981774-d9f534e0c617?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZGlzaHxlbnwwfHwwfHx8MA%3D%3D",
      description: "Honeyed, Juicy, Scrumptious, Luscious.",
    },
    {
      photo: "	https://c.ndtvimg.com/gws/1855/assets/1.jpeg?1612944800",
      description: "Creamy, Rich, Scrumptious, Flavorsome",
    },
  ];
  const testimonials: Testimonial[] = [
    {
      title: "TRANSPORTED TO PARIS",
      content:
        "One bite and I felt like I was back in France. Chef Ingrid and her team have outdone themselves!",
      author: "Teddy, Entrepreneur",
    },
    {
      title: "LOVELY BISTRO",
      content:
        "The ambience was great - and the food even better! Simple recipes done well, with top-notch ingredients. Will be back!",
      author: "Dani, Mother",
    },
    {
      title: "TRANSPORTED TO PARIS",
      content:
        "Eating here made me miss Paris so much. What a treat! I will be bringing my client meetings here.",
      author: "Carly, Entrepreneur",
    },
  ];
  const logos = [
    "https://img.freepik.com/premium-vector/restaurant-logo-design_636083-178.jpg",
    "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/fast-food-restaurant-logo%2C-restaurant-logo-design-template-33255790cb8e1186b28609dd9afd4ee6_screen.jpg?ts=1668794604",
    "https://bcassetcdn.com/public/blog/wp-content/uploads/2019/07/18094833/the-red-cafe.png",
    "https://media.istockphoto.com/id/981368726/vector/restaurant-food-drinks-logo-fork-knife-background-vector-image.jpg?s=612x612&w=0&k=20&c=9M26CBkCyEBqUPs3Ls5QCjYLZrB9sxwrSFmnAmNCopI=",
    "https://png.pngtree.com/png-clipart/20220903/ourmid/pngtree-chef-restaurant-logo-png-image_6136204.png",
    "https://img.freepik.com/free-vector/gradient-restaurant-logo-design_23-2151257068.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722729600&semt=ais_hybrid",
    "https://img.freepik.com/premium-vector/restaurant-logo-design_636083-178.jpg",
    "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/fast-food-restaurant-logo%2C-restaurant-logo-design-template-33255790cb8e1186b28609dd9afd4ee6_screen.jpg?ts=1668794604",
    "https://bcassetcdn.com/public/blog/wp-content/uploads/2019/07/18094833/the-red-cafe.png",
    "https://media.istockphoto.com/id/981368726/vector/restaurant-food-drinks-logo-fork-knife-background-vector-image.jpg?s=612x612&w=0&k=20&c=9M26CBkCyEBqUPs3Ls5QCjYLZrB9sxwrSFmnAmNCopI=",
    "https://png.pngtree.com/png-clipart/20220903/ourmid/pngtree-chef-restaurant-logo-png-image_6136204.png",
    "https://img.freepik.com/free-vector/gradient-restaurant-logo-design_23-2151257068.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722729600&semt=ais_hybrid",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? logos.length - 1 : prevIndex - 1
    );
  };

  const visibleLogos = logos.slice(currentIndex, currentIndex + 5);
  if (visibleLogos.length < 5) {
    visibleLogos.push(...logos.slice(0, 5 - visibleLogos.length));
  }
  const localities = [
    {
      name: "Mumbai",
      image:
        "https://in.bmscdn.com/m6/images/common-modules/regions/mumbai.png",
    },
    {
      name: "Delhi-NCR",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/ncr.png",
    },
    {
      name: "Bengaluru",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/bang.png",
    },
    {
      name: "Hyderabad",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/hyd.png",
    },
    {
      name: "Ahmedabad",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/ahd.png",
    },
    {
      name: "Chandigarh",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/chd.png",
    },
    {
      name: "Chennai",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/chen.png",
    },
    {
      name: "Pune",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/pune.png",
    },
    {
      name: "Kolkata",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/kolk.png",
    },
    {
      name: "Kochi",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/koch.png",
    },
  ];
  const [numOffers, setNumOffers] = useState(window.innerWidth > 768 ? 5 : 1);
  const handleResize = () => {
    setNumOffers(window.innerWidth > 768 ? 5 : 1);
  };

  return (
    <div className="menu-dealz">
      {/* Offer Banners and Static Image */}
      <div className="container my-4" style={{ paddingTop: "70px" }}>
        <div className="row">
          <div className="col-lg-9">
            <div
              id="carouselExampleControls"
              className="carousel slide carousel-container"
              data-bs-ride="carousel"
              data-bs-interval="2000"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="https://img.freepik.com/free-vector/flat-design-pizza-sale-banner_23-2149116013.jpg"
                    className="d-block w-100 carousel-image"
                    alt="Offer Banner 1"
                  />
                  <div className="carousel-caption d-md-block">
                    <div className="bg-dark bg-opacity-50 p-2 rounded">
                      <h3 className="text-white">Delicious Pizza Offers!</h3>
                      <button className="btn btn-light mt-2">
                        View Offers
                      </button>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <img
                    src="https://img.freepik.com/free-vector/hand-drawn-fast-food-sale-banner_23-2150970571.jpg"
                    className="d-block w-100 carousel-image"
                    alt="Offer Banner 3"
                  />
                  <div className="carousel-caption d-md-block">
                    <div className="bg-dark bg-opacity-50 p-2 rounded">
                      <h3 className="text-white">Fast Food Deals!</h3>
                      <button className="btn btn-light mt-2">
                        View Offers
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only"></span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only"></span>
              </a>
            </div>
          </div>

          <div
            className="col-lg-3 d-flex align-items-center justify-content-center"
            style={{ borderRadius: "15px", backgroundColor:" #f0e5d8" }}
          >
            <div className="text-center">
              <h1 className="text-dark">Discover Exclusive Deals</h1>
              <h6 className="text-muted">
                Stay tuned for our exclusive offers and discounts on various
                dishes. Enjoy your meal with the best deals!
              </h6>
              <button className="btn btn-outline-primary">Learn More</button>
            </div>
          </div>
        </div>
      </div>

      {/* Scrollable Client Logos */}
      <div className="container my-4">
        <div id="logoCarousel" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row flex-nowrap justify-content-center">
                {visibleLogos.map((logo, index) => (
                  <div className="col-2 p-2 logosimg" key={index}>
                    <img
                      src={logo}
                      alt={`Logo ${index + 1}`}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            onClick={handlePrev}
          >
            <span
              className="carousel-control-prev-icon"
              style={{ backgroundColor: "black" }}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden"></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            onClick={handleNext}
          >
            <span
              className="carousel-control-next-icon"
              style={{ backgroundColor: "black" }}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden"></span>
          </button>
        </div>
      </div>

      <div className="restaurants-container">
        <h1>Restaurants</h1>
        <div className="offers-grid">{renderOffers(numOffers, restaurant1)}</div>

        <button className="all-restaurant-button">View All Restaurants</button>
      </div>
      <br />
      <br />
      <div className="offers-container">
        <h1>OFFERS</h1>
        <div className="offers-tabs">
          <button
            className={`tab-button ${activeTab === "today" ? "active" : ""}`}
            onClick={() => handleTabChange("today")}
          >
            TODAY'S OFFERS
          </button>
          <button
            className={`tab-button ${activeTab === "special" ? "active" : ""}`}
            onClick={() => handleTabChange("special")}
          >
            SPECIAL OFFERS
          </button>
          <button
            className={`tab-button ${activeTab === "upcoming" ? "active" : ""}`}
            onClick={() => handleTabChange("upcoming")}
          >
            UPCOMING OFFERS
          </button>
        </div>
        <div className="offers-grid">
          {activeTab === "today" && renderOffers(numOffers, offer1)}
          {activeTab === "special" && renderOffers(numOffers, offer2)}
          {activeTab === "upcoming" && renderOffers(numOffers, restaurant1)}
        </div>
        <button className="all-offers-button">View All Offers</button>
      </div>
      <br />
      {/*  Location Section */}
      <div className="container my-4">
        <div className="row text-center">
        <h1 className="text-center my-5">LOCALITIES</h1>
          <div className="location-container">
            {localities.map((location, index) => (
              <div key={index} className="location-item">
                <img
                  src={location.image}
                  alt={location.name}
                  className="location-img"
                />
                <p className="location-name">{location.name}</p>
              </div>
            ))}
          </div>
        </div>
        <button className="all-restaurant-button">View All Localities</button>
      </div>
      <br />
       {/*  Dish Section */}
       <div className="dish-container">
  <div className="container">
    <h1 className="text-center my-5">POPULAR DISHES</h1>
    <div className="row">
      {dishes.map((dish, index) => (
        <div key={index} className="col-lg-3 col-md-4 col-sm-6">
          <div className="dish-card">
            <img
              src={dish.photo}
              alt="Dish Photo"
              className="dish-photo"
            />
            <div className="dish-content">
              {/* <h5 className="dish-title">{dish.name}</h5>
              <p className="dish-category">{dish.category}</p> */}
              <p className="dish-description">{dish.description}</p>
              {/* <p className="dish-price">{dish.price}</p> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  <button className="all-restaurant-button">View All Dishes</button>
</div>


      <br />
      <div className="testimonials-section">
  <h1 className="text-center">WHAT OUR CLIENTS SAY</h1>
  <div className="row justify-content-center">
    {testimonials.map((testimonial, index) => (
      <div key={index} className="testimonial">
        <div className="quote-icon">“</div>
        <h3>{testimonial.title}</h3>
        <p>{testimonial.content}</p>
        <p className="author">- {testimonial.author}</p>
      </div>
    ))}
  </div>
</div>
    </div>
  );
};

export default RestaurantMenu;
