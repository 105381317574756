import React, { useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Autocomplete, Marker } from '@react-google-maps/api';
import { fromLatLng, setKey, setLanguage, setRegion } from 'react-geocode';

// Set your Google Maps Geocoding API key.
setKey("AIzaSyD9YXBj6SVdE-6cWrgApOar0w2WSzdloBY"); // Replace with your actual API key.
setLanguage("en"); // Optional: Set language for geocoding responses.
setRegion("es"); // Optional: Set region for geocoding responses.

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 25.196687852753595,
  lng: 55.274774165509136,
};

// URL or Base64 string of your custom marker image.
const locationIconRed = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KICA8cGF0aCBkPSJNNTAsMTAwQzY2LjUsODQuNSw3NSw3NC4yLDc1LDY1YzAtMTIuNi0xMC4zLTIzLTIzLTIzcy0yMywxMC4zLTIzLDIzYzAsOS4yLDguNSwxOS41LDI1LDM1bDIuNSwyLjVsMi41LTIuNUM0NSwxMTkuNSw1MCw4NS41LDUwLDc1IiBzdHlsZT0iZmlsbDpyZ2IoMjQ1LDAsMCkiLz4KICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjY1IiByPSIxMiIgc3R5bGU9ImZpbGw6d2hpdGU7Ii8+Cjwvc3ZnPg==';





function GoogleMapComp({ onLocationSelect }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD9YXBj6SVdE-6cWrgApOar0w2WSzdloBY",
    libraries: ['places'] // Add 'places' library for Autocomplete
  });

  const [map, setMap] = useState(null);
  const [placeName, setPlaceName] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);
  const autocompleteRef = useRef(null);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(10);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    console.log(`Clicked coordinates: Latitude: ${lat}, Longitude: ${lng}`);

    // Get place name from latitude and longitude.
    fromLatLng(lat, lng)
      .then(({ results }) => {
        const address = results[0].formatted_address;
        console.log(`Place name: ${address}`);
        onLocationSelect(address, lat, lng);
        setPlaceName(address); // Set the place name to state.
        setMarkerPosition({ lat, lng }); // Set marker position.
      })
      .catch((error) => {
        console.error('Error fetching place name:', error);
      });
  };

  const handlePlaceSelected = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setMap((prevMap) => {
        prevMap.panTo(place.geometry.location);
        return prevMap;
      });
      onLocationSelect(place.formatted_address, lat, lng);
      setPlaceName(place.formatted_address); // Set the place name to state.
      setMarkerPosition({ lat, lng }); // Set marker position.
    }
  };
  const containerStyle = {
    width: '100%',
    height: '100vh', // Full height for the map
    position: 'relative' as 'relative', // Necessary for absolute positioning of the input
  };

  const inputStyle = {
    position: 'absolute' as 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
    width: '50%',
    height: '40px',
    padding: '10px',
    boxSizing: 'border-box' as 'border-box',
    marginBottom: '10px',
    
  };

  return isLoaded ? (
    <div style={containerStyle}>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceSelected}
      >
        <input
          type="text"
          placeholder="Search for a location"
          style={inputStyle}
        />
      </Autocomplete>
      
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={20}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleMapClick} // Handle map clicks.
      >
        {markerPosition && (
          <Marker
            position={markerPosition}
            icon={{
              url: locationIconRed,
              scaledSize: new window.google.maps.Size(50, 50), // Adjust the size as needed.
            }}
          />
        )}
      </GoogleMap>
    </div>
  ) : <></>;
}

export default React.memo(GoogleMapComp);
