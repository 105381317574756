import React from 'react';
import Select from 'react-select';

const SearchableDropdown = ({ name, options = [], onChange, value }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '47px', // Set the minimum height
      height: '47px', // Set the fixed height
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '47px', // Match the container height
      display: 'flex',
      alignItems: 'center', // Center the content vertically
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px', // Remove any extra margin
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '47px', // Match the container height
    }),
  };

  const selectedOption = options.find(option => option.value === value) || null;

  return (
    <Select
      name={name}
      options={options}
      onChange={onChange}
      value={selectedOption}
      isSearchable
      styles={customStyles}
    />
  );
};

export default SearchableDropdown;
