import React from "react";

export default function About() {
  return (
    <>
      {/* ======= About Section ======= */}
      <div id="about" className="about"></div>
      {/* End About Section */}
    </>
  );
}
