import React, { useEffect, useState } from "react";
import "./scroll.css";
import { BsArrowUp } from "react-icons/bs";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      <button
        onClick={scrollToTop}
        className={`btn position-fixed rounded-circle text-white align-item-center  ${
          isVisible ? "visible" : "invisible"
        }`}
        id="myBtn"
        title="Go to top"
        style={{
          right: "15px",
          bottom: "15px",
          width: "44px",
          height: "44px",
          backgroundColor: "rgb(18, 153, 1)",
          opacity: isVisible ? 1 : 0,
          transition: "all 0.4s",
        }}
      >
        <BsArrowUp style={{ fontWeight: "bold !important" }} />
      </button>
    </>
  );
};

export default ScrollToTopButton;
