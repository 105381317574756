import React, { useState, useEffect } from 'react';
import { UserInterface } from './interFace';
import api from "../../api";
import { toast } from 'react-toastify';

interface UpdateModalProps {
  user: UserInterface & { roleId?: string }; // Add roleId as optional
  onClose: () => void;

  userListFetchData: () => void;
}

const UpdateModal: React.FC<UpdateModalProps> = ({ user, onClose, userListFetchData }) => {
  const [userInfo, setUserInfo] = useState<UserInterface & { roleId?: string }>({
    ...user,
    roleId: user.userRole?._id || ''
  });
  const [roles, setRoles] = useState<{ _id: string; name: string }[]>([]);
  const [errors, setErrors] = useState<{ name?: string; userRole?: string; contactNumber?: string }>({});


  useEffect(() => {
    console.log(user)
    const fetchRoles = async () => {
      try {
        const response = await api.get('/api/identity/restaurant-user-role/');
        const rolesData = response.data.data;
        setRoles(rolesData);

        // Auto-fill user role if available
        if (userInfo.roleId) {
          const userRole = rolesData.find((role: { _id: string; name: string }) => role._id === userInfo.roleId);
          if (userRole) {
            setUserInfo(prevState => ({
              ...prevState,
              roleId: userRole._id // Set roleId for consistency
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, [userInfo.roleId]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

    const { name, value } = event.target;
    const updatedValue = event.target.type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setUserInfo(prevState => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };
  const validate = (): boolean => {
    const newErrors: { name?: string; userRole?: string; contactNumber?: string } = {};

    if (!userInfo.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!userInfo.contactNumber.trim()) {
      newErrors.contactNumber = "Contact Number required";
    }
    if (!userInfo.roleId || !userInfo.roleId.trim()) {
      newErrors.userRole = "User Role is required.";
    }

    // const contactNumberPattern = /^[0-9]{10}$/;
    // if (!contactNumberPattern.test(userInfo.contactNumber)) {
    //   newErrors.contactNumber = "Contact number must be a 10-digit number.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    // Extract only the necessary fields
    const { isActive, profileUrl, name, contactNumber, roleId } = userInfo;

    const updatedUser = {
      isActive,
      profileUrl,
      name,
      contactNumber,
      roleId,
    };

    try {
      const response = await api.put(`/api/identity/user/${userInfo._id}`, updatedUser);
      userListFetchData();
      onClose()
      if (response.status !== 200) {
        throw new Error('Network response was not ok');

      }

      const result = response.data;
      console.log('User updated successfully:', result);
      toast.success("User updated successfully!");
      // Perform any additional actions on successful update
    } catch (error: any) {
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error('Error response from server:', error.response.data);
        
      } else if (error.request) {
        // Request was made but no response received
        console.error('No response received:', error.request);
      } else {
        // Something else happened
        console.error('Error updating user:', error.message);
      }
    }
  };

  return (
    <div className="modal"
      tabIndex={-1}
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">UPDATE USER DETAILS</h1>
            <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="formGroupExampleInput2" className="form-label">NAME<span className="text-danger"> *</span></label>
                  <input
                    type="text"
                    placeholder="Enter User Name"
                    name="name"
                    className="form-control"
                    id="formGroupExampleInput2"
                    value={userInfo?.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}

                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="formGroupExampleInput4" className="form-label">CONTACT NUMBER<span className="text-danger"> *</span></label>
                  <input
                    type="text"
                    placeholder="Enter User Contact Number"
                    name="contactNumber"
                    className="form-control"
                    id="formGroupExampleInput4"
                    value={userInfo?.contactNumber}
                    onChange={handleInputChange}
                  />
                  {errors.contactNumber && <div className="text-danger">{errors.contactNumber}</div>}

                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="formGroupExampleInput7" className="form-label">USER ROLE<span className="text-danger"> *</span></label>
                  <select
                    name="roleId"
                    className="form-control"
                    id="formGroupExampleInput7"
                    value={userInfo.roleId || ''}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>Select User Role</option>
                    {roles.map(role => (
                      <option key={role._id} value={role._id}>{role.name}</option>
                    ))}
                  </select>
                  {errors.userRole && <div className="text-danger">{errors.userRole}</div>}

                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="formGroupExampleInput5" className="form-label">PROFILE URL</label>
                  <input
                    type="text"
                    placeholder="Enter User Profile URL"
                    name="profileUrl"
                    className="form-control"
                    id="formGroupExampleInput5"
                    value={userInfo.profileUrl ?? ''}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="formGroupExampleInput6" className="form-label custom-label">IS ACTIVE</label>
                  <input
                    type="checkbox"
                    name="isActive"
                    className="form-check-input"
                    id="formGroupExampleInput6"
                    checked={userInfo.isActive}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                <button type="submit" className="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;

