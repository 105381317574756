// import { Form, Input, Select, InputProps, InputRef } from "antd";
// import { FormInstance, FormItemProps, Rule } from "antd/es/form";
// import { SelectValue } from "antd/lib/select";

// interface Props {
//   name: string;
//   label: string;
//   disabled: any;
//   placeholder?: string;
//   rules?: Rule[];
//   inputProps?: InputProps & React.RefAttributes<InputRef>;
//   formItemProps?: FormItemProps;
//   form?: FormInstance;
// }

// export const StringFormItem: React.FC<React.PropsWithChildren<Props>> = ({
//   name,
//   label,
//   placeholder,
//   disabled,
//   rules = [],
//   inputProps = {},
//   formItemProps = {},
// }) => {
//   return (
//     <Form.Item name={name} label={label} rules={rules} {...formItemProps}>
//       <Input
//         style={{ width: "100%" }}
//         disabled={disabled}
//         placeholder={placeholder}
//         {...inputProps}
//       />
//     </Form.Item>
//   );
// };

// const { Option } = Select;

// interface CustomStringFormItemProps {
//   name: string;
//   label: string;
//   disabled: any;
//   placeholder?: string;
//   rules?: Rule[];
//   formItemProps?: FormItemProps;
//   form?: FormInstance;
//   dropdownOptions?: { value: string | number; label: React.ReactNode }[];
//   style?: React.CSSProperties; // Add the style prop
// }

// export const CustomStringFormItem: React.FC<
//   React.PropsWithChildren<CustomStringFormItemProps>
// > = ({
//   name,
//   label,
//   placeholder,
//   disabled,
//   rules = [],
//   formItemProps = {},
//   dropdownOptions = [],
//   style = {},
// }) => {
//   return (
//     <Form.Item name={name} label={label} rules={rules} {...formItemProps}>
//       <Select
//         style={{ width: "100%" }}
//         disabled={disabled}
//         placeholder={placeholder}
//         onChange={(value) => {
//           /* Handle onChange event if needed */
//         }}
//       >
//         {dropdownOptions.map((option) => (
//           <Option key={option.value} value={option.value as SelectValue}>
//             {option.label}
//           </Option>
//         ))}
//       </Select>
//     </Form.Item>
//   );
// };

//////////////////////////////////////////
import { Form, Input, Select, InputProps, InputRef, Checkbox } from "antd";
import { FormInstance, FormItemProps, Rule } from "antd/es/form";
import { SelectValue } from "antd/lib/select";
// import { useEffect, useState } from "react";

interface Props {
  name: string;
  label: string;
  disabled: any;
  placeholder?: string;
  rules?: Rule[];
  inputProps?: InputProps & React.RefAttributes<InputRef>;
  formItemProps?: FormItemProps;
  form?: FormInstance;
}

export const StringFormItem: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  label,
  placeholder,
  disabled,
  rules = [],
  inputProps = {},
  formItemProps = {},
  form,
}) => {
  return (
    <Form.Item name={name} label={label} rules={rules} {...formItemProps}>
      <Input
        style={{ width: "100%" }}
        disabled={disabled}
        placeholder={placeholder}
        {...inputProps}
      />
    </Form.Item>
  );
};

const { Option } = Select;

interface CustomStringFormItemProps {
  name: string;
  label: string;
  disabled: any;
  placeholder?: string;
  rules?: Rule[];
  formItemProps?: FormItemProps;
  form?: FormInstance;
  dropdownOptions?: { value: string | number; label: React.ReactNode }[];
  style?: React.CSSProperties; // Add the style prop
}

export const CustomStringFormItem: React.FC<
  React.PropsWithChildren<CustomStringFormItemProps>
> = ({
  name,
  label,
  placeholder,
  disabled,
  rules = [],
  formItemProps = {},
  dropdownOptions = [],
  style = {},
}) => {
  return (
    <Form.Item name={name} label={label} rules={rules} {...formItemProps}>
      <Select
        style={{ width: "100%", ...style }}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(value) => {
          /* Handle onChange event if needed */
        }}
      >
        {dropdownOptions.map((option) => (
          <Option key={option.value} value={option.value as SelectValue}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

interface CheckboxProps {
  name: string;
  label: string;
  disabled: any;
  rules?: Rule[];
  form?: FormInstance;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({
  name,
  label,
  disabled,
  rules,
  form,
}) => {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Checkbox disabled={disabled} />
    </Form.Item>
  );
};

export default CustomCheckbox;
