import React from "react";
import { AboutSection } from "../../constants/constant";

export default function About() {
  return (
    <>
      {/* ======= About Section ======= */}
      <div id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
           
            <p>
              {AboutSection.lEARN_MORE}
              <span>{AboutSection.ABOUT_US}</span>
            </p>
          </div>
          <div className="row gy-4">
            <div
              className="col-lg-7 position-relative about-img"
              style={{ backgroundImage: "url(img/about.jpg)" }}
              data-aos="fade-up"
              data-aos-delay={150}
            >
              <div className="call-us position-absolute">
                <h4>Enquire Now</h4>
                <p><a href="tel:+971 50724 5494" style={{color:'#ec2727'}}>+971 50724 5494</a></p>
              </div>
            </div>
            <div
              className="col-lg-5 d-flex align-items-end"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="content ps-0 ps-lg-5">
                <p className="fst-italic">
                At MenuDealz, we're dedicated to enhancing the dining experience for both restaurants and their customers. With our innovative platform, we bring convenience, excitement, and efficiency to the world of dining. <b>Discover the Ultimate Dining Experience:</b>
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check2-all" /> <b>Digital Menus</b>: Say goodbye to traditional paper menus! Restaurants can now showcase their mouthwatering dishes, complete with vivid images, detailed descriptions, and customizable options, all accessible at the touch of a button.

                  </li>
                  <li>
                    <i className="bi bi-check2-all" /> <b>Real-Time Specials</b>: Hungry for savings? Our platform allows restaurants to display their latest specials, deals, and promotions in real-time, ensuring that diners never miss out on a delicious opportunity.

                  </li>
                  <li>
                    <i className="bi bi-check2-all" /> <b>Instant Offers</b>: Help reduce food wastage and delight diners with instant offers! Restaurants can quickly update their menus with limited-time offers on surplus items, encouraging customers to indulge in delightful culinary experiences while minimizing waste
                  </li>
                  <li>
                    <i className="bi bi-check2-all" /> <b>Upcoming Events</b>  : From themed nights to chef's specials, our platform enables restaurants to promote upcoming events and exclusive dining experiences, enticing customers to mark their calendars and reserve their tables in advance.
                  </li>
                </ul>
                <p>
                <b>Sign up today to discover exclusive offers, explore tantalizing menus, and embark on a culinary journey like never before.</b>
                
                </p>

                {/* <div className="position-relative mt-4">
                  <img
                    src="assets/img/about-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <a
                    href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                    className="glightbox play-btn"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Section */}
    </>
  );
}
