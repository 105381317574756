import { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { message } from "antd";
interface ChildComponentProps {
  handleRegisterClose: () => void;
  SetMenuID: (id: string) => void;
  SetPayloaddata: (formData: any) => void;
}
interface Option {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}
interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}
const DishRegister = (props: ChildComponentProps) => {
  const { handleRegisterClose, SetMenuID, SetPayloaddata } = props;
  const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [selectedDish, setSelectedDish] = useState([]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [dishOptions, setDishOptions] = useState<Option[]>([]);
  const [errors, setErrors] = useState<{
    selectedDishId?: string;
    price?: string;
  }>({});
  const [formData, setFormData] = useState({
    selectedDishId: "",
    price: 0,
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: name === "price" ? parseFloat(value) || null : value,
    });
  };
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    setSelected(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedDishId: selectedValue,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    const postData = {
      selectedDishId: formData.selectedDishId,
      price: formData.price,
    };

    try {
      const response = await api.post(
        "/api/restaurant/today-special/",
        postData
      );
      SetPayloaddata(formData);
      
      setFormData({
        selectedDishId: "",
        price: 0,
      });

      message.success(response.data.message);
      // setResponse(true);
      handleRegisterClose();
      SetMenuID(response.data.data._id);
    } catch (error: any) {
      // Explicitly type 'error' as 'any'
      console.error("There was an error!", error);

      if (error.response) {
        // If the error has a response, extract the error message from the response data
        message.error(error.response.data.message);
      } else {
        // If there is no response, display a generic error message
        message.error("Please fill out all required fields.");
      }
    }
  };
  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
    getAllActiveDishes();
  }, []);
  if (loading) {
    return <Loader />;
  }
  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=100"
      );
      console.log("success response", response.data);
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };

  const validate = (): boolean => {
    const newErrors: { selectedDishId?: string; price?: string } = {};

    if (!formData.selectedDishId.trim()) {
      newErrors.selectedDishId = "Please Select Dish.";
    }
    if (!formData.price) {
      newErrors.price = "Price required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <div
        className="modal"
        tabIndex={-1}
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Special Today's Special
              </h1>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleRegisterClose}
              ></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        Select The Dish<span className="text-danger"> *</span>
                      </label>
                      <Select
                        value={dishOptions.find(
                          (option) => option.value === selected
                        )}
                        onChange={handleChange}
                        options={dishOptions}
                        classNamePrefix="react-select"
                      />
                      {errors.selectedDishId && (
                        <div className="text-danger">
                          {errors.selectedDishId}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        Price Of The Dish ({defaultCurrency})
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        value={formData.price}
                        placeholder="Enter Price Of The Dish"
                        onChange={handleInputChange}
                      />
                      {errors.price && (
                        <div className="text-danger">{errors.price}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleRegisterClose}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save & Next <i className="bi bi-caret-right-fill"></i>
                </button>
                <ToastContainer />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DishRegister;
