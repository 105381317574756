import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { IoRestaurant } from "react-icons/io5";
import { MdOutlineMenuBook } from "react-icons/md";
import { BiSolidDish } from "react-icons/bi";
// import { AiFillSliders } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Oval } from "react-loader-spinner";
// import Loader from "../Loader";
import { SidebarSection } from "../../constants/constant";
import api from "../../api";
import { RestaurantBranchInterface } from "../Branches/interFace";
import { BranchStatusCountInterface } from "./BranchStatusCountInterface";
import { DishMenuCountInterface } from "./DishMenuCountInterface";

function Dashboard() {
  // const [loading, setLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;
  // const [userData, setUserData] = useState(null);
  const [restaurantId, setRestaurantId] = useState("");
  const [currentPage] = useState(1);
  const [search] = useState("");
  const [activeBranchData, setActiveBranchData] = useState<RestaurantBranchInterface[]>([]);
  const [suspendedBranchData, setSuspendedBranchData] = useState<RestaurantBranchInterface[]>([]);
  const [branchStatusCount, setBranchStatusCount] = useState<BranchStatusCountInterface>();
  const [dishMenuCount, setDishMenuCount] = useState<DishMenuCountInterface>();

  useEffect(() => {

    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
    }

  }, []);

  useEffect(() => {
    getBranchCounts();
    getDishAndMenuCounts();
    if (restaurantId) {
      getallBranch(currentPage);
    }
  }, [restaurantId, currentPage, search]);


  const getBranchCounts = async () => {
    try {
      var url = "";
      url =
        `api/identity/restaurant/restaurant-admin/dashboard`;
      const response = await api.get(url);

      setBranchStatusCount(response.data.data);
      console.log("***** ", response.data.data);

      return response.data;
    } catch (error) {
      console.error("There was an error!", error);
      toast.error("Error in API call!");
      return Promise.reject(error);
    }
  };

  const getDishAndMenuCounts = async () => {
    try {
      var url = "";
      url =
        `api/restaurant/menu/restaurant-admin/dashboard`;
      const response = await api.get(url);

      setDishMenuCount(response.data.data);

      return response.data;
    } catch (error) {
      console.error("There was an error!", error);
      toast.error("Error in API call!");
      return Promise.reject(error);
    }
  };

  //Function for Get all branches
  const getallBranch = async (pageNo: any) => {
    try {
      var url = "";
      url =
        `api/identity/restaurant-branch/?page=${pageNo}&limit=${ITEMS_PER_PAGE}&restaurantId=${restaurantId}&searchText=` +
        search;
      const response = await api.get(url);
      //setLoading(false);
      console.log("success response: ", response.data.data);
      const activeBranches = response.data.data.branch.filter((branch: { status: string }) => branch.status === "active");
      setActiveBranchData(activeBranches);

      // For suspended branches
      const suspendedBranches = response.data.data.branch.filter((branch: { status: string }) => branch.status === "suspended");
      setSuspendedBranchData(suspendedBranches);

      // for(var i=0; i<response.data.data.branch.length;i++){
      //   if(response.data.data.branch[i].status ==="active"){
      //     activeBranchData.push(response.data.data.branch[i]);
      //     setActiveBranchData(activeBranchData);
      //     console.log("acivvee");
      //   }
      // }

      // for(var i=0; i<response.data.data.branch.length;i++){
      //   if (response.data.data.branch[i].status ==="suspended"){
      //     suspendedBranchData.push(response.data.data.branch[i]);
      //     setSuspendedBranchData(suspendedBranchData);
      //     console.log("suspnededd");
      //   }
      // }


      return response.data;
    } catch (error) {
      console.error("There was an error!", error);
      toast.error("Error in API call!");
      return Promise.reject(error);
    }
  };
  // const activeBranchCount = branchStatusCount?.data?.branchStatusCounts.find(status => status._id === "active")?.count || 0;

  return (
    <>
      <section className="mar-main">
        <section className="container">
          <div className="mar-d1">
            <h2>{SidebarSection.DASHBOARD_01}</h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item">Dashboard </li>
            </ul>
          </div>
          <div className="row flex-row flex-nowrap overflow-auto">
            <div className="col-md-3 col-12 ">
              <div className="mar-d2">
                <IoRestaurant className="mar-ico1" />
                {branchStatusCount && branchStatusCount.totalBranchCount ? (
                  <h3>{branchStatusCount?.totalBranchCount}</h3>
                ) : (
                  <h3>0</h3>
                )}
                <p>Total Branches</p>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="mar-d2">
                <IoRestaurant className="mar-ico1" />
                {branchStatusCount ? (
                  <h3>
                    {branchStatusCount.branchStatusCounts.find(
                      (status) => status._id === "active"
                    )?.count || 0}
                  </h3>
                ) : (
                  <h3>0</h3>
                )}
                <p>Active Branches</p>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="mar-d2">
                <MdOutlineMenuBook className="mar-ico2" />
                {dishMenuCount && dishMenuCount.menuDetail ? (
                  <h3>{dishMenuCount.menuDetail.menuTotalCount}</h3>
                ) : (
                  <h3>0</h3>
                )
              }
                {/* <h3>{dishMenuCount?.menuDetail.menuTotalCount}</h3> */}


                <p>Menus</p>
                <br />
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="mar-d2">
                <BiSolidDish className="mar-ico3" />
                {dishMenuCount && dishMenuCount.dishDetail ? (
                  <h3>{dishMenuCount.dishDetail.dishTotalCount}</h3>
                ) : (
                  <h3>0</h3>
                )}
                {/* <h3>{dishMenuCount?.dishDetail.dishTotalCount}</h3> */}
                <p>Dishes</p>
                <br />
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="mar-d2">
                <AiFillSliders className="mar-ico4" />
                <h3>4</h3>
                <p>Sliders</p>
              </div>
            </div> */}
          </div>

          <div className="row mb-4 margn-above">
            <div className="col-md-6">
              <h4><b>ACTIVE BRANCHES</b></h4>
              <div className="row ">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">#</th>
                        <th scope="col">BRANCH NAME</th>
                        <th scope="col">PHONE NO.</th>
                        <th scope="col">STATUS</th>

                      </tr>
                    </thead>
                    <tbody>
                      {activeBranchData !== null &&
                        (activeBranchData as any[]).map((item, index) => (
                          <tr key={index}>
                            <td>
                              {(currentPage - 1) * 10 + index + 1}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.contactNumber}</td>
                            <td>

                              {item.status === 'active' ? (
                                <span className="badge bg-success rounded-pill">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}

                              {item.status === 'inactive' ? (
                                <span className="badge bg-error rounded-pill">
                                  Inactive
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}

                              {item.status === 'suspended' ? (
                                <span className="badge bg-warning rounded-pill">
                                  Suspended
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}
                              &nbsp;

                            </td>

                          </tr>
                        ))}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <h4><b>SUSPENDED  BRANCHES</b></h4>
              <div className="row ">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">#</th>
                        <th scope="col">BRANCH NAME</th>
                        <th scope="col">PHONE NO.</th>
                        <th scope="col">STATUS</th>

                      </tr>
                    </thead>
                    <tbody>
                      {suspendedBranchData !== null &&
                        (suspendedBranchData as any[]).map((item, index) => (
                          <tr key={index}>
                            <td>
                              {(currentPage - 1) * 10 + index + 1}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.contactNumber}</td>
                            <td>

                              {item.status === 'active' ? (
                                <span className="badge bg-success rounded-pill">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}

                              {item.status === 'inactive' ? (
                                <span className="badge bg-error rounded-pill">
                                  Inactive
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}

                              {item.status === 'suspended' ? (
                                <span className="badge bg-warning rounded-pill">
                                  Suspended
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill">

                                </span>
                              )}
                              &nbsp;

                            </td>

                          </tr>
                        ))}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>




        </section>

      </section>
    </>
  );
}
export default Dashboard;
