// import { createSlice } from "@reduxjs/toolkit";

// interface UserState {
//   isAuthenticated: boolean;
// }

// const initialState: UserState = {
//   isAuthenticated: false,
// };

// const userSlice = createSlice({
//   name: "user",
//   initialState,
//   reducers: {
//     login: (state) => {
//       state.isAuthenticated = true;
//     },
//     logout: (state) => {
//       state.isAuthenticated = false;
//     },
//   },
// });

// export const { login, logout } = userSlice.actions;
// export default userSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  isAuthenticated: boolean;
}

const initialState: UserState = {
  isAuthenticated: JSON.parse(sessionStorage.getItem('isAuthenticated') || 'false'),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
      sessionStorage.setItem('isAuthenticated', 'true');
    },
    logout: (state) => {
      state.isAuthenticated = false;
      sessionStorage.setItem('isAuthenticated', 'false');
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
