import axios from 'axios';
let baseURL = process.env.REACT_APP_API_URL
// Create an Axios instance
const api = axios.create({
// baseURL: baseURL, // Base URL for your API
headers: {
    "Content-Type": "application/json"
},
});

// Request interceptor to add the auth token before request is sent
api.interceptors.request.use(
(config) => {
    const token = localStorage.getItem('yourAuthTokenKey'); // Retrieve your token from localStorage or wherever it's stored
    if (token) {
     config.headers['Authorization'] = `Bearer ${token}`; // Attach token to headers
    }
    return config;
},
(error) => {
    console.log("errororo ");
    return Promise.reject(error);
}
);

export default api;