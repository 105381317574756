import React from "react";
// import { FileDragger } from '../inputs/FileDragger'
// import { StringFormItem } from '../inputs/StringFormItem'
// import { Button, Col, Form, Modal, Row } from "antd";
// import { Link } from "react-router-dom";
import About from "../about";
import Top from "../top-section";
import JoinUs from "../joinus";
import RestaurantList from "../restaurants";
// import Countersection from "../counterSection";
// import Slider from "../slider";
// import OurPlatform from "../ourPlatform";
export default function Home() {
  return (
    <div>
      {/* <Slider/> */}
      <Top />
      <About />
      <JoinUs />
      <RestaurantList />
      {/* <OurPlatform/> */}
      {/* <Countersection/> */}
    </div>
  );
}
