export enum Messages {
  SUCCESS = "Success msg",
  WARNING = "Warning msg",
}

export enum SidebarSection {
  DASHBOARD_01 = "Dashboard",
  DISH_LIST = "Dish List",
  CATEGORY_LIST = "Category List",
  MENU_LIST = "Menu List",
  BRANCH_MENU_LIST = "Branch Menu List",
  BRANCH_LIST = "Branch List",
  USER_LIST="User List",
  RESTAURANT_LIST = "Restaurant List",
  A_THEME_SETTING = "A Theme Setting",
  R_THEME_SETTING = "R Theme Setting",
  DISHES_MANAGEMENT = "Dishes Management",
}
export enum AboutSection {
  lEARN_MORE = "Learn More ",
  ABOUT_US = "About Us ",
}
export enum HeaderSection {
  MENUDEALZ = "MenuDealz ",
}
export enum RegistrationSection {
  REGISTER = "Grow  ",
  WITH_US = "Your Reach : ",
  TO_GET_LISTED = "Register Your Restaurant with Us!",
}
export enum TopSection {
  ENJOY_YOUR_HEALTHY = "Digitize your Restaurant ",
  DELICIOUS_FOOD = "Showcase menus and deals online for customers",
}
export enum OurPlatformSection {
  WELCOME_TO_OUR_PLATFORM = "Welcome To Our Platform ",
}
export enum RestaurantSection {
  OUR_MENU = "Our Menu ",
  CHECK_OUR = " Check Our ",
  YUMMY_MENU = " Yummy Menu",
}
