import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "../redux/store/store";
import SideMenu from "../sidemenu";
import SideBarHeader from "../header/LoggedInHeader";
import "./index.scss";
import { RestaurantDetailInterface } from "../restaurant-list/interFace";
import api from "../../api";
import Loader from "../Loader";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RestaurantInfo = () => {
  // const [userData, setUserData] = useState(null);
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantDetail, setRestaurantDetail] =
    useState<RestaurantDetailInterface>();
  const [error, setError] = useState<string | null>(null);
  const [showNav, setShowNav] = useState(true);
  const [loading, setLoading] = useState(true);
  const [createdDate, setCreatedDate] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
      console.log("rest idd " + restaurantId);
    }
  }, []);

  useEffect(() => {
    console.log("rest idd 111 " + restaurantId);
    if (restaurantId) {
      console.log("rest idd 222 " + restaurantId);
      fetchRestaurantDetailData(restaurantId);
    }
  }, [restaurantId]);

  const fetchRestaurantDetailData = async (id: string) => {
    try {
      setLoading(true);
      await api
        .get(`/api/identity/restaurant/${id}`)
        .then(function (response) {
          setLoading(false);
          const restaurantDetailData = response.data.data;
          setRestaurantDetail(restaurantDetailData);


          setCreatedDate(getLocalFormattedDate(restaurantDetailData.createdAt));
          setUpdatedDate(getLocalFormattedDate(restaurantDetailData.updatedAt));

          setError(
            restaurantDetailData ? null : "No results found for the given ID."
          );
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          toast.error("Error in api call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("Error fetching restaurant detail data:", error);
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const editRestaurant = () => {
    navigate("/profile");
  };

  const editDocument = () => {
    navigate("/document-upload");
  };

  const getLocalFormattedDate = (date: string) => {
    var localDate = new Date(date);

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth returns 0-based index
    const day = String(localDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  return (
    <div>
      {error && <div style={{ color: "red" }}>{error}</div>}
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    <h4 className="rest-info">Restaurant Details</h4>
                  </div>
                  <div className="col text-end ">
                    <Button
                      // icon={<ArrowRightOutlined />}
                      style={{ background: "var(--color-orange)", color: "white" }}
                      // onClick={form.submit}
                      onClick={() => editRestaurant()}
                    >
                      <i className="bi bi-pencil-square text-align-center"></i>
                    </Button>
                  </div>
                </div>

                <div className="modal-body table-responsive">
                  <table className="table table-bordered view-table table-light infoTable">
                    <tbody>
                      <tr>
                        <th>RESTAURANT NAME</th>
                        <td>{restaurantDetail?.name}</td>
                        <th>BRAND NAME</th>
                        <td>{restaurantDetail?.brandDetail.name}</td>
                      </tr>
                      <tr>
                        <th>TAGLINE</th>
                        <td>{restaurantDetail?.tagline}</td>
                        <th>OWNER DETAILS</th>
                        <td colSpan={3}>
                          <div>
                            <span>Name:</span>&nbsp;&nbsp;
                            {restaurantDetail?.owner.name}
                          </div>
                          <div>
                            <span>Contact Number:</span>
                            &nbsp;&nbsp;{restaurantDetail?.owner.contactNumber}
                          </div>
                          <div>
                            <span>Email:</span>&nbsp;&nbsp;
                            {restaurantDetail?.owner.email}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>RESTAURANT CONTACT NUMBER</th>
                        <td>{restaurantDetail?.contactNumber}</td>
                        <th>RESTAURANT MOBILE NUMBER</th>
                        <td>{restaurantDetail?.mobileNumber} </td>
                      </tr>
                      <tr>
                        <th>RESTAURANT EMAIL ID</th>
                        <td>{restaurantDetail?.email}</td>
                        <th>RESTAURANT STATUS</th>
                        <td>
                          {restaurantDetail?.isActive ?
                           <span className="badge bg-success rounded-pill">
                              Active</span> : <span className="badge bg-error rounded-pill">InActive</span>}
                        </td>
                      </tr>

                      <tr>
                        <th>PLACE</th>
                        <td>{restaurantDetail?.place}</td>
                        <th>CONTACT PERSON DETAILS</th>
                        <td colSpan={3}>
                          <div>
                            <span>Name:</span>&nbsp;&nbsp;
                            {restaurantDetail?.contactPerson.name}
                          </div>
                          <div>
                            <span>Contact Number:</span>
                            &nbsp;&nbsp;
                            {restaurantDetail?.contactPerson.contactNumber}
                          </div>
                          <div>
                            <span>Email:</span>&nbsp;&nbsp;
                            {restaurantDetail?.contactPerson.email}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>CREATE DATE</th>
                        <td>{createdDate}</td>
                        <th>UPDATE DATE</th>
                        <td>{updatedDate} </td>
                      </tr>
                      <tr>
                        <th>NO. OF BRANCHES</th>
                        <td>{restaurantDetail?.branchCount}</td>
                        <th>DEFAULT CURRENCY</th>
                        <td colSpan={3}>
                          <div>
                            <span>Name:</span>&nbsp;&nbsp;
                            {restaurantDetail?.defaultCurrency.name}
                          </div>
                          <div>
                            <span>Code:</span>&nbsp;&nbsp;
                            {restaurantDetail?.defaultCurrency.code}
                          </div>
                          <div>
                            <span>Symbol:</span>&nbsp;&nbsp;
                            {restaurantDetail?.defaultCurrency.symbol}
                          </div>
                        </td>
                      </tr>
                      <tr>

                        <th>APPROVAL STATUS</th>
                        <td>
                          {restaurantDetail?.isApproved ?
                            <span className="badge bg-success rounded-pill">
                              APPROVED</span> : <span className="badge bg-error rounded-pill">APPROVAL PENDING</span>}
                          {/* {restaurantDetail?.isApproved ? "APPROVED" : "APPROVAL PENDING"} */}
                        </td>
                        <th></th>
                        <td></td>
                      </tr>
                      {restaurantDetail?.attachement !== null &&
                        (restaurantDetail?.attachement as any[]).map((item, index) => (
                          <tr key={index}>
                            <th> {item.typeName === 'ELD' ? 'IDENTITY DOCUMENT' : item.typeName === 'VAT' ? 'TAX REGISTRATION DOCUMENT': item.typeName === 'Logo' ? 'LOGO': item.typeName === 'Trading License' ? 'TRADE LICENCE': item.typeName === 'Contract' ? '': item.typeName === 'Additional Document' ? 'ADDITIONAL DOCUMENT': item.typeName === 'Additional Document' ? 'ADDITIONAL DOCUMENT' : item.typeName}</th>
                            <td>
                              {item.url && (
                                <>
                                  {item.url.endsWith(".pdf") ? <a
                                    className="btn btn-danger rounded-circle act-btn m-1"
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="bi bi-file-pdf"></i>
                                  </a> : <img src={item.url} height={100} width={100} alt="MenuDealz"></img>
                                  }
                                  <a
                                    onClick={() => editDocument()}
                                    className="btn-action rounded-circle act-btn "
                                  >
                                    <i className="bi bi-pencil-square "></i>
                                  </a>
                                </>
                              )}
                            </td>
                            <th></th>
                            <td>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantInfo;
