import React, { useEffect, useState } from "react";
// import { RestaurantSection } from "../../../constants/constant";
// Using ESM specification
import "./restaurantMenu.css";
// Using a bundler like webpack
import GLightbox from "glightbox";
import { toast } from "react-toastify";
import api from "../../../api";
import defaultImg from "./img/menu/no img.png";
// import { useParams } from "react-router-dom";
import { Dish, RestaurantBranchMenuDetailInterface } from "./RestaurantBranchMenuDetailInterface";
import { message } from "antd";
// Define the type for props
interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;

  }
}
export default function RestaurantMenu({ data }: RestaurantMenuProps) {

  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [menuTypeCode, setMenuTypeCode] = useState("");
  const [selectedCurrencyData, setSelectedCurrencyData] = useState(String);

  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface>();

  // State to manage active tab
  const [activeTab, setActiveTab] = useState(restaurantBrantMenuDetails?.menuDetail.category[0]._id);

  // Function to handle tab change
  const handleTabChange = (categoryId: string) => {
    setActiveTab(categoryId);
  };

  const viewDetails = (dish: any) => {
    console.log(JSON.stringify(dish));
    setShowViewBranchModal(true);
    setSelectedDish(dish);
  }

  useEffect(() => {
    console.log("inside RestaurantMenu component menuId: " + data.menuId + " branchId: " + data.branchId + " restaurantId: " + data.restaurantId);
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }

  }, [data.menuId, data.branchId, data.restaurantId]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const assignmentIdFromUrl = params.get('a');
    if (assignmentIdFromUrl) {
      setAssignmentId(assignmentIdFromUrl);
    }
  }, []);
  const [assignmentId, setAssignmentId] = useState(String);

  const getRestaurantBranchMenuDetails = async () => {
    try {

      var url = "/api/restaurant/branch-menu/assigned-menu/" + assignmentId + "?branchId=" + data.branchId + "&restaurantId=" + data.restaurantId;

      const response = await api.get(url);
      console.log("success menu details response", response.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setMenuTypeCode(response.data.data.menuDetail.menuTypeCode)
      // let currencyData = localStorage.getItem("currencyDetails");
      // setSelectedCurrencyData(currencyData);
      // console.log("currencyData" + currencyData);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };
  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setSelectedCurrencyData(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);
  useEffect(() => {
    // Ensure that restaurantBrantMenuDetails has been updated before setting the active tab
    if (restaurantBrantMenuDetails && restaurantBrantMenuDetails.menuDetail.category.length > 0) {
      handleTabChange(restaurantBrantMenuDetails.menuDetail.category[0]._id);
    }
  }, [restaurantBrantMenuDetails]);

  useEffect(() => {
    // Initialize GLightbox when the component mounts
    const lightbox = GLightbox({
      selector: ".glightbox", // selector for lightbox elements
    });

    // Optionally, you can clean up the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []); // empty dependency array ensures the effect runs only once


  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };

  return (
    <>
      {/* ======= Menu Section ======= */}
      <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2 className="menu_name">{restaurantBrantMenuDetails?.menuDetail.name}</h2>
            <h2>
              <span>{restaurantBrantMenuDetails?.menuDetail.description}</span>
            </h2>
                                 {menuTypeCode === "buffetMenu" && (
                                    <>
                                      <div className="price">Total Price({selectedCurrencyData}) :- {restaurantBrantMenuDetails.menuDetail.totalPrice}</div>
                                    </>
                                  )}
            
          </div>
          <ul className="nav nav-tabs scroll-menu" style={{ backgroundColor: "aliceblue" }} data-aos="fade-up" data-aos-delay={200}>
            {restaurantBrantMenuDetails?.menuDetail.category !== null &&
              restaurantBrantMenuDetails?.menuDetail.category?.map((item, index) => (
                <li key={index} className="nav-item">
                  <a
                    className={`nav-link ${activeTab === item._id ? 'active' : ''}`}
                    onClick={() => handleTabChange(item._id)}
                  >
                    <h4>{item.name}</h4>
                  </a>
                </li>
              ))}
          </ul>
          {/* // Render tab content based on activeTab */}
          {restaurantBrantMenuDetails?.menuDetail.category !== null &&
            restaurantBrantMenuDetails?.menuDetail.category?.map((item, index) => (
              <div key={index} className={`tab-pane fade ${activeTab === item._id ? 'show active' : ''}`} id={item._id}>
                {activeTab === item._id && // Conditionally render content only if the tab is active
                  <>
                    <br />  <br />  <br />  <br />
                    <div className="row gy-5">
                      {item.dish?.map((dish, dishIndex) => (
                        <div key={dishIndex} className="col-lg-4 menu-item menu-item-margin">
                          <div className="d-flex justify-content-between">
                            <div className="row cursor-pointer" onClick={() =>
                              viewDetails(dish)
                            }>
                              <div className="col-lg-12 col-6 dish-img">

                                <a
                                >
                                  <img src={dish?.imageAttachement[0]?.url ? dish?.imageAttachement[0]?.url : defaultImg} className="menu-img1 img-fluid" alt="" style={{ width: '100%', height: '250px', objectFit: 'contain' }} />
                                </a>
                              </div>
                              <div className="col-lg-12 col-6">
                                <p className="ingredients">
                                  <h4 className="dish-name cursor-pointer">{dish.name}</h4>
                                  {menuTypeCode === "defaultMenu" && (
                                    <>
                                      <div className="price">{selectedCurrencyData} {dish.price}</div>
                                      <div>{dish.description.substring(0, 70)}...</div>
                                      <div>{dish.preparationTime} Minutes | Good for {dish.servingSize} people</div>
                                    </>
                                  )}
                                  
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr className="line" />
                        </div>
                      ))}
                    </div>
                  </>
                }
              </div>
            ))
          }
          {showViewBranchModal && (
            <div
              className="modal modal-lg"
              tabIndex={-1}
              style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Dish Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleViewBranchClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                      {/* <div className="dish-img-details"> <img src={selectedDish?.imageAttachement[0]?.url ? selectedDish?.imageAttachement[0]?.url : '/img/menu/menu-item-2.png'} className="menu-img1 img-fluid" alt="" width={310} height={310} /></div> */}

                      <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                          {selectedDish.imageAttachement.concat(selectedDish.videoAttachement).map((media, index) => {
                            const isImage = media.url.match(/\.(jpeg|jpg|gif|png|jfif)$/i);
                            const isVideo = media.url.match(/\.(mp4|webm|ogg)$/i);

                            return (
                              <div key={media._id} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                {isImage && <img src={media.url} className="d-block w-100" alt={`Slide ${index}`} style={{ width: '100%', height: '310px', objectFit: 'contain' }} />}
                                {isVideo && (
                                  <video className="d-block w-100" width={250} height={310} controls>
                                    <source src={media.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        {(selectedDish.imageAttachement.length + selectedDish.videoAttachement.length) > 0 && (
                          <>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                              <span className="carousel-control-prev-icon" style={{ backgroundColor: "black" }} aria-hidden="true"></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                              <span className="carousel-control-next-icon" style={{ backgroundColor: "black" }} aria-hidden="true"></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </>
                        )}
                      </div>

                      <table className="table table-bordered view-table">
                        <tbody>
                          <tr>
                            <th>Dish Name</th>
                            <td>{selectedDish.name}</td>
                          </tr>
                          <tr>
                            <th>Dish Details </th>
                            <td>{selectedDish.description}</td>
                          </tr>
                          {menuTypeCode === "defaultMenu" && (
                                    <>
                                      <tr>
                            <th>Price</th>
                            <td>{selectedCurrencyData} {selectedDish.price}</td>
                          </tr>
                          <tr>
                            <th>Preparation Time(Minutes)</th>
                            <td>{selectedDish.preparationTime}</td>
                          </tr>
                          <tr>
                            <th>Good For</th>
                            <td>{selectedDish.servingSize} Person</td>
                          </tr>
                                    </>
                                  )}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* End Menu Section */}
    </>
  );
}
