import { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import { message } from "antd";
import Select from "react-select";

interface ChildComponentProps {
  handleRegisterClose: () => void;
  SetPayloaddata: (formData: any) => void;
  SetMenuID: (id: string) => void;
}

interface Option {
  value: string;
  label: string;
  code: string;
  description: string;
  price: number;
}

interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}

const DishRegister = (props: ChildComponentProps) => {
  const { handleRegisterClose, SetPayloaddata, SetMenuID } = props;
  const [loading, setLoading] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [selected, setSelected] = useState<string>("");
  const [dishOptions, setDishOptions] = useState<Option[]>([]);
  const [errors, setErrors] = useState<{
    name?: string;
    totalPrice?: string;
    description?: string;
    selectedDishId?: string;
    offerPrice?: string;
    quantity?: string;
  }>({});
  const [formData, setFormData] = useState({
    typeCode: "discountedSpecialOffer",
    name: "",
    description: "",
    totalPrice: 0,
    offerPrice: 0,
    dish: [
      {
        selectedDishId: "",
        quantity: null,
      },
    ],
  });

  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setSelected(selectedValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: prevFormData.dish.map((dish) => ({
        ...dish,
        selectedDishId: selectedValue,
      })),
    }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "totalPrice" || name === "offerPrice"
          ? parseFloat(value) || 0
          : value,
    });
  };

  const handleDishQuantityChange = (index: number, value: number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: prevFormData.dish.map((dish, i) =>
        i === index ? { ...dish, quantity: value } : dish
      ),
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      const response = await api.post(
        "/api/restaurant/special-offer/",
        formData
      );
      SetPayloaddata(formData);
      SetMenuID(response.data.data._id);
      setFormData({
        typeCode: "discountedSpecialOffer",
        name: "",
        description: "",
        totalPrice: 0,
        offerPrice: 0,
        dish: [
          {
            selectedDishId: "",
            quantity: null,
          },
        ],
      });

      message.success(response.data.message);
      handleRegisterClose();
    } catch (error: any) {
      console.error("There was an error!", error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Please fill out all required fields.");
      }
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
    getAllActiveDishes();
  }, []);

  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=100"
      );
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      toast.error("Error fetching dish data!");
    }
  };

  const validate = (): boolean => {
    const newErrors: {
      name?: string;
      totalPrice?: string;
      description?: string;
      selectedDishId?: string;
      offerPrice?: string;
      quantity?: string;
    } = {};

    if (!formData.name.trim()) {
      newErrors.name = "Offer Name is required.";
    }
    if (!formData.totalPrice) {
      newErrors.totalPrice = "Total Price is required.";
    }
    if (!formData.description.trim()) {
      newErrors.description = "Description is required.";
    }
    if (!selected) {
      newErrors.selectedDishId = "Dish selection is required.";
    }
    if (!formData.offerPrice) {
      newErrors.offerPrice = "Offer Price is required.";
    }
    if (
      formData.dish[0].quantity === null ||
      formData.dish[0].quantity === undefined
    ) {
      newErrors.quantity = "Quantity is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <label className="form-label">
                  Offer Name<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  placeholder="Enter Offer Name"
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Total Price ({defaultCurrency})
                  <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="totalPrice"
                  value={formData.totalPrice}
                  placeholder="Enter Total Price"
                  onChange={handleInputChange}
                />
                {errors.totalPrice && (
                  <div className="text-danger">{errors.totalPrice}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Description about the Offer
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows={5}
                  placeholder="Enter description about the offer"
                  maxLength={200}
                ></textarea>
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </div>
            </div>
            <div className="col">
              <div className="mb-3">
                <label className="form-label">
                  Select The Dish<span className="text-danger"> *</span>
                </label>
                <Select
                  value={dishOptions.find(
                    (option) => option.value === selected
                  )}
                  onChange={handleChange}
                  options={dishOptions}
                  classNamePrefix="react-select"
                />
                {errors.selectedDishId && (
                  <div className="text-danger">{errors.selectedDishId}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Offer Price ({defaultCurrency})
                  <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="offerPrice"
                  value={formData.offerPrice}
                  placeholder="Enter Offer Price"
                  onChange={handleInputChange}
                />
                {errors.offerPrice && (
                  <div className="text-danger">{errors.offerPrice}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Quantity<span className="text-danger"> *</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="quantity"
                  value={formData.dish[0].quantity}
                  onChange={(e) =>
                    handleDishQuantityChange(0, parseInt(e.target.value))
                  }
                />
                {errors.quantity && (
                  <div className="text-danger">{errors.quantity}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleRegisterClose}
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary">
          Save & Next <i className="bi bi-caret-right-fill"></i>
          </button>
          <ToastContainer />
        </div>
      </form>
    </>
  );
};

export default DishRegister;
