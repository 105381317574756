import React, { useEffect, useState } from "react";
import SideMenu from "../sidemenu";
import "./category.scss";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import { StringFormItem } from "../inputs/StringFormItem";
import { Form, message } from "antd";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import { CategoryInterface } from "./InterFace";
import TextareaFormItem from "../inputs/TextareaFormItem";
import 'bootstrap/dist/css/bootstrap.min.css';
// declare var bootstrap: any;
function Menu() {
  // const [category, setCategory] = useState<CategoryInterface | null>(null);
  const [categoryData, setCategoryData] = useState<CategoryInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [form] = Form.useForm();
  const [showNav, setShowNav] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showMenuModal, setShowMenuModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [categoryStatus, setCategoryStatus] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (id: string) => {
    setShowStatusUpdateModal(true)
    setCategoryId(id);
  };
  const handleDeleteShow = (id: string) => {
    setShowDeleteModal(true);
    setCategoryId(id);
  }
  useEffect(() => {
    getallCategory();
  }, [form]);
  const prepareEditCategory = async (item: any) => {
    setCategoryId(item._id);
    setLoading(true);
    try {
      await api.get('api/restaurant/menu-category/' + item._id).then(
        function (response) {
          setLoading(false);
          console.log("sucess respp get category");
          console.log(response.data);
          // setCategory(response.data.data);
          console.log("name of cate " + response.data.data.name + " desc of cat " + response.data.data.description);
          form.setFieldsValue({ name: response.data.data.name, description: response.data.data.description });
          handleShow();
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!')
          message.error("Error in API call!")
          setLoading(false);
          console.log(error)
          return Promise.reject(error)
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const addOrUpdateCategory = async () => {

    let url = "";
    const categoryRequestObject = await form.validateFields();
    console.log("add or update category " + JSON.stringify(categoryRequestObject));

    setLoading(true);
    if (categoryId === "") {
      url = 'api/restaurant/menu-category/';
      try {
        await api.post(url, categoryRequestObject).then(
          function (response) {
            console.log("sucess respp");
            message.success(response.data.message)
            setLoading(false);
            toast.success("Category added Successfully!");
            console.log(response.data);
            handleClose();
            getallCategory();
            form.resetFields();
            return response.data
          }
        ).catch(
          function (error) {
            setLoading(false);
            console.log('Show error notification!');
            handleClose();
            message.error("Error in API call!")
            console.log(error)
            return Promise.reject(error)
          }
        );
      } catch (error) {
        console.error("There was an error!", error);
      }
    } else {
      url = 'api/restaurant/menu-category/' + categoryId;
      try {
        await api.put(url, categoryRequestObject).then(
          function (response) {
            message.success(response.data.message)
            console.log("sucess respp");
            setLoading(false);
            toast.success("Category updated Successfully!")
            console.log(response.data);
            setCategoryId("");
            handleClose();
            getallCategory();
            form.setFieldsValue({ name: "", description: "" });
            return response.data
          }
        ).catch(
          function (error) {
            console.log('Show error notification in update!');
            setLoading(false);
            toast.error("Error in api call!");
            console.log(error)
            return Promise.reject(error)
          }
        );
      } catch (error) {
        console.error("There was an error!", error);
      }
    }

  };
  const getallCategory = async () => {
    setLoading(true);
    try {
      await api.get('api/restaurant/menu-category/').then(
        function (response) {
          setLoading(false);
          console.log("sucess respp");
          console.log(response.data);
          setCategoryData(response.data.data);
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!')
          toast.error("Error in api call!")
          setLoading(false);
          console.log(error)
          return Promise.reject(error)
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const deleteCategory = async () => {
    setLoading(true);
    try {
      await api.delete('api/restaurant/menu-category/' + categoryId).then(
        function (response) {
          console.log("sucess respp");
          message.success("deleted Successfully!");
          setLoading(false);
          console.log(response.data);
          handleDeleteClose();
          setCategoryId("");
          getallCategory();
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!');
          message.error(error.response.data.message);
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status ");
    try {
      await api.put('api/restaurant/menu-category/status/' + categoryId + "?statusCode=" + categoryStatus).then(
        function (response) {
          message.success("Status updated Successfully!");
          setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setCategoryId("");
          setCategoryStatus("");
          getallCategory();
          return response.data
        }
      ).catch(
        function (error) {
          console.log('Show error notification!');
          toast.error("Error in api call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            <div className="pt-4 pb-4">
              <section className="main shadow-sm">
                <div className="row mb-4">
                  <div className="col">
                    {/* <h4>Category List</h4> */}
                    <h4>{SidebarSection.CATEGORY_LIST}</h4>
                  </div>
                  <div className="col text-end">

                    <button
                      type="button"
                      className="btn addbtn"
                      onClick={handleShow}
                    >
                      <i className="bi bi-clipboard-plus "></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">CATEGORY NAME</th>
                          <th scope="col">DESCRIPTION</th>
                          <th scope="col">CODE</th>
                          <th scope="col">STATUS</th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryData !== null &&
                          (categoryData as any[]).map((item, index) => (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.description}</td>
                              <td>{item.code}</td>
                              <td>
                                {item.status === 'active' ? (
                                  <span className="badge bg-success rounded-pill">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge bg-error rounded-pill">

                                  </span>
                                )}

                                {item.status === 'inactive' ? (
                                  <span className="badge bg-error rounded-pill">
                                    Inactive
                                  </span>
                                ) : (
                                  <span className="badge bg-error rounded-pill">

                                  </span>
                                )}

                                {item.status === 'suspended' ? (
                                  <span className="badge bg-warning rounded-pill">
                                    Suspended
                                  </span>
                                ) : (
                                  <span className="badge bg-error rounded-pill">

                                  </span>
                                )}
                                &nbsp;
                                <button
                                 
                                  className="btn-action rounded-circle act-btn"
                                  onClick={() => handleStatusUpdateShow(item._id)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  
                                  className="btn-action rounded-circle act-btn"
                                  onClick={() => prepareEditCategory(item)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                                &nbsp;
                                <button
                                  onClick={() => handleDeleteShow(item._id)}
                                  className="btn-action rounded-circle act-btn "
                                >
                                  <i className="bi bi-trash "></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <div
        className="modal fade"
        id="category-modal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      > */}
          {showModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Category Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <Form
                    data-aos-delay={100}
                    data-aos="fade-up"
                    role="form"
                    form={form}
                    layout="vertical"
                    onFinish={addOrUpdateCategory}
                  >
                    <div className="modal-body">
                      <div className="mb-3">
                        <StringFormItem
                          label="Category Name(Ex.Starter/Breakfast/Lunch/Dinner,etc.)"
                          name="name"
                          disabled={""}
                          placeholder="Category name *"
                          rules={[
                            {
                              message: "The input is not valid category!",
                            },
                            {
                              required: true,
                              message: "Please input category!",
                            },
                          ]}
                        />
                      </div>
                      <div className="mb-3">
                        <TextareaFormItem
                          label="Description"
                          name="description"
                          placeholder="Description "

                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}>
                        Close
                      </button>
                      <button type="button"
                        onClick={addOrUpdateCategory} className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )}
          {showDeleteModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Delete category?
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleDeleteClose}
                    ></button>
                  </div>
                  <div className="delete-text"> Are you sure you want to delete?</div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleDeleteClose}>
                      Cancel
                    </button>
                    <button type="button"
                      onClick={deleteCategory} className="btn btn-primary">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {showMenuModal && (
            <div className="modal-content">
              <div className="mb-3">
                <label className="form-label">Menu<span className="text-danger"> *</span></label>
                <div>
                  <select className='form-control down-arrow' name="cars" id="cars">
                    <option value="volvo">Breakfast</option>
                    <option value="saab">Lunch</option>
                    <option value="mercedes">Dinner</option>
                    <option value="audi">Starters</option>
                    <option value="audi">Main Course</option>
                  </select>
                </div>
              </div>
            </div>
          )} */}
          {showStatusUpdateModal && (
            <div className="modal" tabIndex={-1} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Update status?
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleStatusUpdateClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                      <label className="form-label">
                        Select status <span className="text-danger"> *</span>
                      </label>
                      <div>
                        <select className="form-control down-arrow" value={categoryStatus} onChange={(e) => setCategoryStatus(e.target.value)} >
                        <option value="" disabled>Select Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="suspended">Suspended</option>
                        </select>
                      </div>
                    </div></div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleStatusUpdateClose}>
                      Cancel
                    </button>
                    <button type="button"
                      onClick={updateStatus} className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      )};
    </>
  );
}

export default Menu;
