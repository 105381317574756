import React from "react";
import { DishDetailsInterface } from "./interface";

interface DishDetailViewProps {
  dishDetailData: DishDetailsInterface | null;
  handleClose: () => void;
  defaultCurrency: string;
}
const getLocalFormattedDate = (date: string | undefined) => {
  if (date) {
    var localDate = new Date(date);

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth returns 0-based index
    const day = String(localDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }


  return "";
}

const DishDetailView: React.FC<DishDetailViewProps> = ({
  dishDetailData,
  handleClose,defaultCurrency
}) => {
  return (
    <>
      {" "}
      <form>
        <div className="modal-body table-responsive">
          <table className="table table-bordered view-table">
            <tbody>
              <tr>
                <th>DISH NAME</th>
                <td>{dishDetailData?.name}</td>
                <th>DISH CODE</th>
                <td>{dishDetailData?.code}</td>
              </tr>
              <tr>
                <th>DESCRIPTION</th>
                <td>{dishDetailData?.description}</td>
                <th>INGREDIENTS</th>
                {/* <td>
                  {dishDetailData?.ingrediants.map((ingredient) => (
                    <div key={ingredient._id}>
                      {`${ingredient.quantity} ${ingredient.unit} ${ingredient.name}`}
                    </div>
                  ))}
                </td> */}
                <td colSpan={3}>
                  <table className="sub-table">
                    <tbody>
                      <tr>
                        <th>NAME</th>
                        <th>QUANTITY</th>
                        <th>UNIT</th>
                        <th>DESCRIPTION </th>
                      </tr>
                      {dishDetailData?.ingrediants.map((ingredient) => (
                        <tr key={ingredient._id}>
                          <td>{ingredient.name}</td>
                          <td>{ingredient.quantity}</td>
                          <td>{ingredient.unit}</td>
                          <td>{ingredient.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th>PRODUCTION COST ({defaultCurrency})</th>
                <td>{dishDetailData?.productionCost}</td>
                <th>PREPARATION TIME</th>
                <td>{dishDetailData?.preparationTime} minutes</td>
              </tr>
              <tr>
                <th>SERVING SIZE</th>
                <td>{dishDetailData?.servingSize}</td>
                <th>CATEGORY TAGS</th>
                <td colSpan={3}>
                  {dishDetailData?.categoryTag.map((tag) => (
                    <p key={tag}>
                      <p>{tag}</p>
                    </p>
                  ))}

                </td>
              </tr>
              <tr>
                <th>ACTIVE</th>
                <td>{dishDetailData?.isActive ? "Yes" : "No"}</td>
                <th>STATUS</th>
                {/* <td>
                  {dishDetailData?.status}</td> */}
                <td>{dishDetailData?.status === 'active' ? (
                  <span className="badge bg-success rounded-pill">
                    Active
                  </span>
                ) : (
                  <span className="badge bg-error rounded-pill">

                  </span>
                )}

                  {dishDetailData?.status === 'suspended' ? (
                    <span className="badge bg-error rounded-pill">
                      Suspended
                    </span>
                  ) : (
                    <span className="badge bg-error rounded-pill">

                    </span>
                  )}

                  {dishDetailData?.status === 'creating' ? (
                    <span className="badge bg-warning rounded-pill">

                      Creating
                    </span>
                  ) : (
                    <span className="badge bg-error rounded-pill">

                    </span>
                  )}</td>

              </tr>
              <tr>
                <th>CREATED ON</th>
                <td>{getLocalFormattedDate(dishDetailData?.createdAt)}</td>
                <th>CREATED BY</th>
                <td>{dishDetailData?.createdBy}</td>
              </tr>
              <tr>
                <th>UPDATED ON</th>
                <td>{getLocalFormattedDate(dishDetailData?.updatedAt)}</td>
                <th>UPDATED BY</th>
                <td>{dishDetailData?.updatedBy}</td>
              </tr>
              <tr>
                <th>DISH IMAGE</th>
                {/* <td>
                  <img src={dishDetailData?.imageAttachement[0]?.url ? dishDetailData?.imageAttachement[0]?.url : '/img/menu/menu-item-2.png'} className="menu-img1 img-fluid" alt="" width={610} height={610} />
                </td> */}
                <td>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    {dishDetailData?.imageAttachement?.map((image, index) => (
                      <img
                        key={image._id}
                        src={image.url ? image.url : '/img/menu/menu-item-2.png'}
                        className="menu-img1 img-fluid"
                        alt={`Dish Img ${index + 1}`}
                        width={130}
                        height={110}
                      />
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th>DISH VIDEO</th>
                <td>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    {dishDetailData?.videoAttachement?.map((video, index) => (
                      <video
                        key={video._id}
                        src={video.url ? video.url : '/video/default-video.mp4'}
                        className="menu-video img-fluid"

                        width={130}
                        height={100}
                        controls
                      />
                    ))}
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        {/* <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Reject
          </button>
          <button
            type="button"
            className="btn btn-success"
            // onClick={handleApprove}
          >
            Approve
          </button>
        </div> */}
      </form>
    </>
  );
};

export default DishDetailView;
