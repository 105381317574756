import React from "react";
import { Space, Table, Button } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Input } from "antd";
import { SidebarSection } from "../../constants/constant";
interface DataType {
  key: string;
  name: string;
  Price: number;
  Description: string;
  Ingradiants: string;
  Categories: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Price",
    dataIndex: "Price",
    key: "Price",
  },
  {
    title: "Small Description",
    dataIndex: "Description",
    key: "Description",
  },
  {
    title: "Ingradiants",
    dataIndex: "Ingradiants",
    key: "Ingradiants",
  },
  {
    title: "Categories",
    dataIndex: "Categories",
    key: "Categories",
  },
  //   {
  //     title: 'Tags',
  //     key: 'tags',
  //     dataIndex: 'tags',
  //     render: (_, { tags }) => (
  //       <>
  //         {tags.map((tag) => {
  //           let color = tag.length > 5 ? 'geekblue' : 'green';
  //           if (tag === 'loser') {
  //             color = 'volcano';
  //           }
  //           return (
  //             <Tag color={color} key={tag}>
  //               {tag.toUpperCase()}
  //             </Tag>
  //           );
  //         })}
  //       </>
  //     ),
  //   },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a>
          <EditOutlined />
        </a>
        <a>
          <DeleteOutlined />
        </a>
      </Space>
    ),
  },
];

const data: DataType[] = [
  {
    key: "1",
    name: "Dummy Dish 1",
    Price: 32,
    Description: "This small description",
    Ingradiants: "Ingradiants",
    Categories: "Categoties",
  },
  {
    key: "2",
    name: "Dummy Dish 2",
    Price: 32,
    Description: "This small description",
    Ingradiants: "Ingradiants",
    Categories: "Categoties",
  },
  {
    key: "3",
    name: "Dummy Dish 3",
    Price: 32,
    Description: "This small description",
    Ingradiants: "Ingradiants",
    Categories: "Categoties",
  },
  {
    key: "4",
    name: "Dummy Dish 3",
    Price: 32,
    Description: "This small description",
    Ingradiants: "Ingradiants",
    Categories: "Categoties",
  },
];

export default function Dishlist() {
  const list = useSelector((state) => state);

  console.log(list, "list");
  return (
    <div>
      <div className="container-fluid">
        <h4>{SidebarSection.DISHES_MANAGEMENT}</h4>
        <div className="row">
          <div className="col-sm-12 col-md-4 col-6">
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search here by name of the dishes"
            />
          </div>
          <div className="col-sm-12 col-md-4 col-6"></div>
          <div className="col-sm-12 col-md-4 col-6 ">
            <div className="float-end">
              <FilterOutlined />
            </div>
          </div>
        </div>
        <Button className="mt-3">Add Dish</Button>
        <div className="mt-3">
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
}
