import React from "react";
import { Link } from "react-router-dom";

export default function JoinUs() {
  return (
    <>
      {/* ======= Join Us Section ======= */}
      <section id="why-us" className="why-us ">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="why-box">
                <h3>Get Started: Register
Your Restaurant Hassle-Free</h3>
                <p>
                To register your restaurant on our platform and utilize its features like creating digital menus, publishing daily offers, and providing instant deals, follow these steps:

                </p>
                <div className="text-center">
                  <Link to="/register" className="more-btn">
                    Register<i className="bx bx-chevron-right"></i>
                  </Link>
                </div>
                <div className="text-center mt-3">
                  {/* <Link to="/login">Already Registered With Us?</Link> */}
                  <p className="m-0 p-0 mb-2">Already Registered With Us?</p>
                  <Link to="/login" className="more-btn">
                    Login<i className="bx bx-chevron-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-8 d-flex align-items-center">
              <div className="row gy-4">
                <div
                  className="col-xl-4"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div
                    className="icon-box d-flex flex-column justify-content-center align-items-center"
                    style={{ background: "aliceblue" }}
                  >
                    <i className="bi bi-1-circle-fill"></i>
                    {/* <h4>Step-1</h4> */}
                    <p style={{textAlign:"justify"}}>
                    <b>Sign Up:</b> Please click the “Register button”. You'll need to provide basic information about your restaurant, such as its name,, contact details.
                    </p>
                  </div>
                </div>

                <div
                  className="col-xl-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div
                    className="icon-box d-flex flex-column justify-content-center align-items-center"
                    style={{ background: "aliceblue" }}
                  >
                    <i className="bi bi-2-circle-fill"></i>
                    {/* <h4>Step-2</h4> */}
                    <p style={{textAlign:"justify"}}>
                    <b>Create Your Profile:</b> Once you've signed up, complete your restaurant profile. Add details such as your menu items, cuisine type, opening hours, and any special features or amenities your restaurant offers. Upload high-quality photos of your dishes to showcase your offerings to potential customers.
                    </p>
                  </div>
                </div>

                <div
                  className="col-xl-4"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div
                    className="icon-box d-flex flex-column justify-content-center align-items-center"
                    style={{ background: "aliceblue" }}
                  >
                    <i className="bi bi-3-circle-fill"></i>
                    {/* <h4>Step-3</h4> */}
                    <p style={{textAlign:"justify"}}>
                    <b>Set Up Your Digital Menu:</b> Use our user-friendly interface to create a digital menu for your restaurant. You can easily add, edit, or remove menu items, customize descriptions and prices, and categorize items by type. Make sure your menu is visually appealing and easy to navigate for customers browsing online or on their mobile devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Join Us Section */}
    </>
  );
}
