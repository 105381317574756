import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import SideMenu from "../sidemenu";
import "./index.scss";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import axios from "axios";
import api from "../../api";
import Pagination from "./pagination";
import { UserDetailInterface, UserInterface } from "./interFace";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import AddModel from "./AddUser";
import 'bootstrap';
// import { Upload } from "antd";
import UpdateModal from "./UpdateUser";
// declare var bootstrap: any;


function UsertList() {
  const [showNav, setShowNav] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserInterface[]>(
    []
  );
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [userDetailData, setUserDetailData] = useState<UserDetailInterface>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [userId, setuserId] = useState("");
  const [actionType] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const ITEMS_PER_PAGE = 10;
  //const [page, setPage] = useState(1);

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setuserId("");
  };
  const handleDeleteShow = (itemId: string) => {
    setShowDeleteModal(true);
    setuserId(itemId!);
  };

  const userListFetchData = async (page: number, searchText: string) => {
    try {
      setLoading(true);
      const response = await api.get(
        `/api/identity/user/?page=${page}&limit=${ITEMS_PER_PAGE}&searchText=${searchText}`
      );
      const userData = response.data.data.user;
      console.log(userData);
      const totalCount = response.data.data.totalCount;

      setUserData(userData || []);
      setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
      setError(
        userData.length > 0
          ? null
          : "No results found for the given search text."
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userListFetchData(currentPage, searchText);
  }, [currentPage, searchText]);

  const getuserDetails = (userId: string) => {
    fetchuserDetailData(userId);
  };

  const handleDelete = async () => {
    try {
      if (userId !== null) {
        setLoading(true);
        await api.delete(`/api/identity/user/${userId}`);
        setuserId("");
        handleDeleteClose();
        userListFetchData(currentPage, searchText);
        toast.success("User Deleted Sucessfully")
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
    }
  };



  const addUserDetailData = async (data: any) => {
    try {
      console.log(data);
      if (data !== null) {
        const response = await api.post(`/api/identity/user/`, data);
        console.log("User added successfully!", response.data);

        // Optionally update the user list after adding a new user
        userListFetchData(currentPage, searchText);

        // Show success notification
        toast.success("User added successfully!");

        // Close the modal
        handleClose();
      }
    } catch (error) {
      console.error("Error adding user:", error);

      // Show error notification
      if (axios.isAxiosError(error) && error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const fetchuserDetailData = async (id: string | null) => {
    try {
      if (id !== null) {
        const response = await api.get(`/api/identity/user/${id}`);
        const userDetailData = response.data.data;
        console.log(userDetailData);
        setUserDetailData(userDetailData || null);
        setuserId(id);
        handleShow();
        setError(
          userDetailData ? null : "No results found for the given ID."
        );
      }
    } catch (error) {
      console.error("Error fetching user detail data:", error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };



  const getLocalFormattedDate = (date: string | undefined) => {
    if (date) {
      var localDate = new Date(date);

      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth returns 0-based index
      const day = String(localDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }


    return "";
  }


  // const [userList, setUserList] = useState<UserInterface[]>([]); // Assuming you have a list of users
  const [selectedUser, setSelectedUser] = useState<UserInterface | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const openModal = (user: UserInterface) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const openAddModal = () => {

    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {

    setIsAddModalOpen(false);
  };


  return (
    <div>
      {error && <div style={{ color: "red" }}>{error}</div>}
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          <div className="pt-4 pb-4">
            <section className="main shadow-sm">
              <div className="row mb-4">
                <div className="col d-flex justify-content-between">
                  <div className="col-md-6 mb-3"><h4>{SidebarSection.USER_LIST}</h4></div>
                  <div className="col-md-5 mb-3"><form className="form-inline my-2 my-lg-0 ">
                    <input
                      className="form-control mr-sm-2 col-md-6"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </form></div>

                  <div>
                    <button className="btn addbtn"
                      onClick={() => openAddModal()}>
                      <i className="bi bi-clipboard-plus"></i>
                    </button>
                  </div>
                  


                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">NAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">CONTACT NUMBER</th>
                          <th scope="col">PROFILE URL</th>
                          <th scope="col">IS ACTIVE</th>
                          <th scope="col">USER ROLE</th>

                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData !== null &&
                          (userData as any[]).map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.contactNumber}</td>
                              <td>{item.profileUrl}</td>
                              <td>
                                
                                  {item.isActive ? <span className="badge bg-success rounded-pill">Active</span> : <span className="badge bg-error rounded-pill">InActive</span>}
                               
                              </td>

                              <td>{item.userRole?.name}</td>


                              <td className="p-1 d-flex">

                                <button
                                  key={item._id}
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => openModal(item)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>


                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => getuserDetails(item._id)}

                                >
                                  <i className="bi bi-eye"></i>

                                </button>

                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() =>

                                    handleDeleteShow(item._id)
                                  }
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div><br /></div>

                  <div className=" d-flex  justify-content-end pe-md-5 ">
                    {!loading && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete user?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text mb-3 custom-label-left">
                {" "}

                <h5
                >Are you sure you want to delete?</h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && selectedUser && (
        <UpdateModal user={selectedUser} onClose={closeModal}  userListFetchData={() => userListFetchData(currentPage, searchText)} />
      )}

      {isAddModalOpen && (
        <AddModel addUserDetailData={addUserDetailData} onClose={closeAddModal} />
      )}
      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg ">
            <div className="modal-content ">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {actionType === "delete" ? "" : "User Details"}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>

              <form>
                <div className="modal-body table-responsive">
                  <table className="table table-bordered view-table infoTable">
                    <tbody>
                      <tr>
                        <th>NAME</th>
                        <td>{userDetailData?.name}</td>
                        <th>PROFILE URL</th>
                        <td>{userDetailData?.profileUrl
                        }</td>
                      </tr>
                      <tr>
                        <th>EMAIL</th>
                        <td>{userDetailData?.email}</td>
                        <th>CONTACT NUMBER</th>
                        <td>{userDetailData?.contactNumber}</td>

                      </tr>

                      <tr>

                        <th> CREATED ON</th>
                        <td>{getLocalFormattedDate(userDetailData?.createdAt)}</td>
                        <th>UPDATED ON</th>
                        <td>{getLocalFormattedDate(userDetailData?.updatedAt)}</td>
                      </tr>
                      




                    </tbody>
                  </table>
                </div>

              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default UsertList;
