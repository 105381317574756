import React, { useState } from "react";
import SideMenu from "../sidemenu";
// import { Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import "./restaurant-theme.scss";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
declare var bootstrap: any;
function RestaurantTheme() {
  const [checked, setChecked] = useState(true);

  const handleChange = (val: boolean | ((prevState: boolean) => boolean)) => {
    setChecked(val);
  };
  const [showNav, setShowNav] = useState(true);
  const prepareAddTheme = () => {
    var myModal = new bootstrap.Modal(
      document.getElementById("theme-modal"),
      {}
    );
    myModal.show();
  };

  return (
    <>
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          <div className="pt-4 pb-4">
            <section className="main shadow-sm">
              <div className="row mb-4">
                <div className="col">
                  {/* <h4>R Theme Setting</h4> */}
                  <h4>{SidebarSection.R_THEME_SETTING}</h4>
                </div>
                <div className="col text-end">
                  <button
                    type="button"
                    className="btn addbtn"
                    onClick={prepareAddTheme}
                  >
                    <i className="bi bi-clipboard-plus  "></i>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">#</th>
                        <th scope="col">THEME-COLOR</th>
                        <th scope="col">TEXT-COLOR</th>
                        <th scope="col">ENABLE & DISABLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          <button className="bg-danger theme-8"></button>
                        </td>
                        <td>
                          <button className="theme-7"></button>
                        </td>
                        <td>
                          <div className="app" style={{ textAlign: "justify" }}>
                            <ReactSwitch
                              checked={checked}
                              onChange={handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>
                          <button className="bg-dark theme-6"></button>
                        </td>
                        <td>
                          <button className="theme-4"></button>
                        </td>
                        <td>
                          <div className="app" style={{ textAlign: "justify" }}>
                            <ReactSwitch
                              checked={checked}
                              onChange={handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          <button className="theme-3"></button>
                        </td>
                        <td>
                          <button className="theme-2"></button>
                        </td>
                        <td>
                          <div
                            className="app "
                            style={{ textAlign: "justify" }}
                          >
                            <ReactSwitch
                              checked={checked}
                              onChange={handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>
                          <button className="theme"></button>
                        </td>
                        <td>
                          <button className="theme-1"></button>
                        </td>
                        <td>
                          <div className="app" style={{ textAlign: "justify" }}>
                            <ReactSwitch
                              checked={checked}
                              onChange={handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="theme-modal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Theme Details
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Theme-Color <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="color"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Text-Color <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="color"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestaurantTheme;
