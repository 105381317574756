import React, { useEffect, useState } from "react";
import RestaurantMenu from "./restaurantMenu";
// import { useParams } from "react-router-dom";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { RestaurantDetailInterface } from "../restaurant-list/interFace";
import logo from "./logo/logo.jpeg";

export default function SubscriptionPlan() {

  // const { menuId, branchId, restaurantId } = useParams<{
  //   menuId?: string;
  //   branchId?: string;
  //   restaurantId?: string;
  // }>();

  const [menuId, setMenuId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [restaurantId, setRestaurantId] = useState("");

  const [restaurantDetail, setRestaurantDetail] = useState<RestaurantDetailInterface>();

  useEffect(() => {
    // console.log("menuId: " + menuId + " branchId: " + branchId + " restaurantId: " + restaurantId);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const a = params.get('a');
    const b = params.get('b');
    const c = params.get('c');
    setMenuId(a);
    setBranchId(b);
    setRestaurantId(c);
    if ("" !== c && null != c) {
      fetchRestaurantDetailData();
    }
  }, [restaurantId, menuId, branchId]);

  const fetchRestaurantDetailData = async () => {
    try {
      if ("" !== restaurantId && null !== restaurantId) {
        await api
          .get(`/api/identity/restaurant/${restaurantId}`)
          .then(function (response) {
            const restaurantDetailData = response.data.data;
            localStorage.setItem("currencyDetails", restaurantDetailData.defaultCurrency.symbol);
            setRestaurantDetail(restaurantDetailData);
            return response.data;
          })
          .catch(function (error) {
            console.log("Show error notification!");
            toast.error("Error in api call!");
            console.log(error);
            return Promise.reject(error);
          });
      }
    } catch (error) {
      console.error("Error fetching restaurant detail data:", error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {/* <section
            id="hero"
            className="hero d-flex align-items-center"
            style={{ background: "#585858" }}
          >
            <div className="container" style={{ textAlign: "center" }}>
              <h5
                data-aos="fade-up mt-3"
                style={{ color: "rgb(254, 108, 0)", margin: "20px", fontSize: "25px" }}
              >{restaurantDetail?.name}</h5>
              <img src={logo} width={300} height={300} alt="MenuDealz" />
              <h5
                data-aos="fade-up mt-3"
                style={{ color: "rgb(206 149 0)", margin: "20px" }}
              >
                {restaurantDetail?.tagline}
                <br />
              </h5>
            </div>
          </section> */}
          <RestaurantMenu/>
        </div>
      </div>
      <ToastContainer />
    </div>

  );
}
