import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import SideMenu from "../sidemenu";
import { ToastContainer} from "react-toastify";
import Loader from "../Loader";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import SideBarHeader from "../header/LoggedInHeader";
// import api from "../../api";

function DocumentUpload() {
  const [loading] = useState(false);
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(true);
  const keys = {
    logo: "logo",
    additionalDoc1: "additionalDoc1",
    tradingLicense: "tradingLicense",
    additionalDoc2: "additionalDoc2",
    contract: "contract",
    eld: "Identity Document",
    vat: "Tax registration document",
  };


  const props = (key: any) => ({
    name: key,
    action: process.env.REACT_APP_API_URL + "api/utility/restaurant/attachement",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} File uploaded successfully`);
        info.file.name = "";
        info.file = "";
        info = "";
        key = "";
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} File upload failed.`);
      }
    },
  });

  const viewDocument = () => {
    navigate("/restaurants-info");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`body-area${showNav ? " body-pd" : ""}`}>
            <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
            <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
              <nav className="sideNav">
                <SideMenu />
              </nav>
            </div>
            <div className="pt-4 pb-4">
              <section className="main shadow-sm" >
                <>
                  <div className="row mb-4">
                    <div className="col">
                      <h4 className="rest-info">Upload Documents</h4>
                    </div>
                    <div className="col text-end ">
                      <Button
                        style={{ background: "#ce1212", color: "white" }}
                        onClick={() => viewDocument()}
                      >
                        <i className="bi bi-eye text-align-center"></i>
                      </Button>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 text-center">
                      <Upload {...props(keys.logo)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.logo}
                        >
                          Upload the logo (PNG, JPG, JPEG) <br />
                          512*512 px
                          <span className="text-danger"> *</span>
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-2 text-center">
                      <Upload {...props(keys.tradingLicense)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.tradingLicense}
                        >
                          Upload the Trade License(.pdf format)
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3 text-center">
                      <Upload {...props(keys.eld)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.eld}
                        >
                          Upload the Identity Document(.pdf format)
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3 text-center">
                      <Upload {...props(keys.vat)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.vat}
                        >
                          Upload the Tax registration document(.pdf format)
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3 text-center">
                      <Upload {...props(keys.contract)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.contract}
                        >
                          Upload the Signed MenuDealx Contract(.pdf format)
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3 text-center">
                      <Upload {...props(keys.additionalDoc1)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.additionalDoc1}
                        >
                          Additional Documents 1 (.pdf format)
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-3 text-center">
                      <Upload {...props(keys.additionalDoc2)}>
                        <Button
                          className="text-start"
                          style={{ width: "370px", height: "60px" }}
                          icon={<UploadOutlined />}
                          name={keys.additionalDoc2}
                        >
                          Additional Documents 2 (.pdf format)
                        </Button>
                      </Upload>
                      <div className="validate" />
                    </div>
                  </div>
                </>
              </section>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default DocumentUpload;
