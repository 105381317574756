import React from "react";
import { TopSection } from "../../constants/constant";
import "./index.scss";
import { Link } from "react-router-dom";

const Top: React.FC = () => {
  return (
    <>
      {/* ======= Top Section ======= */}
      <section className="hero d-flex align-items-center justify-content-center section-bg" style={{ background: "aliceblue", textAlign: "center" }}>
        <div className="container text-center">
          <div className="row justify-content-center gy-5">
            <div className="col-lg-8 d-flex flex-column justify-content-center align-items-center">
              <h2 data-aos="fade-up" className="top-head" style={{ color: 'rgb(254, 108, 0)' }}>
                {TopSection.ENJOY_YOUR_HEALTHY}
              </h2>
              <h3>{TopSection.DELICIOUS_FOOD}</h3>
              <p data-aos="fade-up" data-aos-delay="100">
                Click register to start your journey!
              </p>
              <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                <Link to="/register" className="btn-book-a-table">
                  Register With Us
                </Link>
                {/* <a href="#book-a-table" className="btn-book-a-table">Explore Menu</a> */}
                {/* <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Top;
