import { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
interface ChildComponentProps {
  handleRegisterClose: () => void;
}
const DishRegister = (props: ChildComponentProps) => {
  const { handleRegisterClose } = props;
  const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    productionCost: null,
    
  });

 
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Allow any input for name and description
    const allowAnyInput = /[^]+/;

    if (
      name === "name" ||
      name === "description" ||
      allowAnyInput.test(value) ||
      value === ""
    ) {
      setFormData({
        ...formData,
        [name]:
          name === "productionCost" ||
          name === "preparationTime" ||
          name === "servingSize"
            ? parseFloat(value) || 0 // Set value to 0 if it's NaN
            : value,
      });
    } else {
      // Handle the case when the input is not a valid input for name or description
      setFormData({
        ...formData,
        [name]: "",
      });
    }
  };

  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const postData = {
      name: formData.name,
      productionCost: formData.productionCost,
     
    };

    try {
      const response = await api.post("/api/restaurant/dish/", postData);

      // console.log("this dish response", response);
      setFormData({
        name: "",
       productionCost: null,
       
      });

      toast.success(response.data.message);
      // setResponse(true);
      handleRegisterClose();
    } catch (error: any) {
      // Explicitly type 'error' as 'any'
      console.error("There was an error!", error);

      if (error.response) {
        // If the error has a response, extract the error message from the response data
        toast.error(error.response.data.message);
      } else {
        // If there is no response, display a generic error message
        toast.error("Please fill out all required fields.");
      }
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <label className="form-label">
                  Select The Dish<span className="text-danger"> *</span>
                </label>
                <select
                  name="name"
                  className="form-control"
                  id="nameOfDish"
                  value={formData.name}
                  onChange={(e) => handleInputChange(e)}>
                  <option value="" disabled>Select Dish</option>
                  <option key="Dal Baati" value="Dal Baati">Dal Baati</option>
                  <option key="Biryani" value="Biryani">Biryani</option>
                  <option key="Chole bhature" value="Chole bhature">Chole bhature</option>
                  <option key="Dal makhani" value="Dal makhani">Dal makhani</option>
                  <option key="Paneer butter masala" value="Paneer butter masala">Biryani</option>
                </select>
              </div>
              
              <div className="mb-3">
                <label className="form-label">
                  Price Of The Dish  <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={formData.productionCost}
                  placeholder="Enter Price Of The Dish"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            
          </div>
          
          
          
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleRegisterClose}
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Save changes
          </button>
          <ToastContainer />
        </div>
      </form>
      ;
    </>
  );
};

export default DishRegister;
