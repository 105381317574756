import React from "react";
import ReactPaginate from "react-paginate";
import "./index.scss";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const defaultPageRange = 10; // Set your default value

  const pageRangeDisplayed =
    totalPages < defaultPageRange ? totalPages : defaultPageRange;

  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
  };

  // const handlePreviousClick = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <ReactPaginate
      previousLabel={"<"}
      nextLabel={">"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      activeClassName={"active"}
      initialPage={currentPage - 1}
    />
  );
};

export default Pagination;
