import React, { useState } from "react";
// import { StringFormItem } from "../inputs/StringFormItem";
import {
  // Form,
  // Button,
  // message,
  // Upload,
  Radio,
  // UploadProps,
  TimePicker,
  // Steps,
  Checkbox,
  Input,
} from "antd";
import {
  // UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
// import Item from "antd/es/list/Item";

export default function BranchData() {
  const [whFlag, setwhFlag] = useState(1);
  // const [branAddressData, setBranAddressData] = useState<any>([
  //   { id: 1, BranachName: "12", Address: "Location" },
  // ]);
  // const AddBranch = (item: any, index: any) => {
  //   setBranAddressData([
  //     ...branAddressData,
  //     {
  //       id: branAddressData.length + 1,
  //       BranachName: "12",
  //       Address: "Location",
  //     },
  //   ]);
  // };
  // const deleteBranch = (id: any) => {
  //   setBranAddressData(branAddressData.filter((data: any) => data.id !== id));
  // };

  //FUNCTION FOR ADD ROW FOR MONDAY
  // const [workingHrForMon, setWorkingHrForMon] = useState<any>([
  //   { id: 1, openAt: "12", ClosesAt: "12" },
  // ]);
  // const AddMondayValue = (item: any, index: any) => {
  //   setWorkingHrForMon([
  //     ...workingHrForMon,
  //     { id: workingHrForMon.length + 1, openAt: "12", ClosesAt: "12" },
  //   ]);
  // };
  // const deleteData = (id: any) => {
  //   setWorkingHrForMon(workingHrForMon.filter((data: any) => data.id !== id));
  // };

  //FUNCTION FOR ADD ROW FOR Tuesday
  // const [workingHrForTues, setWorkingHrForTues] = useState<any>([
  //   { id: 1, openAt: "12", ClosesAt: "12" },
  // ]);
  // const AddMondayValueTues = (item: any, index: any) => {
  //   setWorkingHrForTues([
  //     ...workingHrForTues,
  //     { id: workingHrForTues.length + 1, openAt: "12", ClosesAt: "12" },
  //   ]);
  // };
  // const deleteDataTues = (id: any) => {
  //   setWorkingHrForTues(workingHrForTues.filter((data: any) => data.id !== id));
  // };

  //FUNCTION FOR ADD ROW FOR Wednesday
  // const [workingHrForWed, setWorkingHrForWed] = useState<any>([
  //   { id: 1, openAt: "12", ClosesAt: "12" },
  // ]);
  // const AddMondayValueWed = (item: any, index: any) => {
  //   setWorkingHrForWed([
  //     ...workingHrForWed,
  //     { id: workingHrForWed.length + 1, openAt: "12", ClosesAt: "12" },
  //   ]);
  // };
  // const deleteDataWed = (id: any) => {
  //   setWorkingHrForWed(workingHrForWed.filter((data: any) => data.id !== id));
  // };

  const [branch, setBranch] = useState([
    {
      email: "user@example.com",
      name: "string",
      tagline: "string",
      address: "string",
      contactNumber: "string",
      isOpenNow: true,
      workingHour: [
        {
          isOpen: true,
          day: 1,
          startTime: "11:30",
          endTime: "11:30",
        },
        {
          isOpen: true,
          day: 2,
          startTime: "11:30",
          endTime: "11:30",
        },
        {
          isOpen: true,
          day: 3,
          startTime: "11:30",
          endTime: "11:30",
        },

        {
          isOpen: true,
          day: 4,
          startTime: "11:30",
          endTime: "11:30",
        },
        {
          isOpen: true,
          day: 5,
          startTime: "11:30",
          endTime: "11:30",
        },
        {
          isOpen: true,
          day: 6,
          startTime: "11:30",
          endTime: "11:30",
        },
        {
          isOpen: true,
          day: 7,
          startTime: "11:30",
          endTime: "11:30",
        },
      ],
      contactPersonName: "string",
      contactPersonEmail: "user@example.com",
      contactPersonContact: "string",
      //   "mapLocationLatitude": 43534543524,
      //   "mapLocationLongitude": 6754323
    },
  ]);

  //FUNCTION FOR ADD NEW BARNCH
  const addNewBranch = () => {
    let newBranchObj = {
      email: "",
      name: "",
      tagline: "",
      address: "",
      contactNumber: "",
      isOpenNow: true,
      workingHour: [
        {
          isOpen: true,
          day: 1,
          startTime: "",
          endTime: "",
        },
        {
          isOpen: true,
          day: 2,
          startTime: "",
          endTime: "",
        },
        {
          isOpen: true,
          day: 3,
          startTime: "",
          endTime: "",
        },

        {
          isOpen: true,
          day: 4,
          startTime: "",
          endTime: "",
        },
        {
          isOpen: true,
          day: 5,
          startTime: "",
          endTime: "",
        },
        {
          isOpen: true,
          day: 6,
          startTime: "",
          endTime: "",
        },
        {
          isOpen: true,
          day: 7,
          startTime: "",
          endTime: "",
        },
      ],
      contactPersonName: "string",
      contactPersonEmail: "user@example.com",
      contactPersonContact: "string",
      //   "mapLocationLatitude": 43534543524,
      //   "mapLocationLongitude": 6754323
    };
    setBranch([...branch, newBranchObj]);
  };

  //FUNCTION FOR ADD NEW BARNCH
  const removeNewBranch = (id: number) => {
    setBranch(branch.filter((data: any, index: number) => index !== id));
  };

  const format = "HH:mm";

  return (
    <div>
      {branch.map((element, index: number) => {
        return (
          <div className="working-hours-main">
            <div className="working-hours-branch">
              <div className="section-header">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2>Branch {index + 1} Details</h2>
                  </div>
                  <div className="d-flex align-items-center">
                    <PlusOutlined
                      style={{ fontSize: "150%" }}
                      onClick={() => addNewBranch()}
                    />
                    {branch.length > 1 && (
                      <span style={{ marginLeft: "12px" }}>
                        <DeleteOutlined
                          onClick={() => removeNewBranch(index)}
                          style={{ fontSize: "150%" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <span className="text-danger">*</span> <label>Email</label>
                  <Input
                    style={{ width: "100%" }}
                    value={element.email}
                    placeholder="Email"
                  />
                  <div className="validate" />
                </div>
                <div className="col-lg-4 col-md-6">
                  <span className="text-danger">*</span>{" "}
                  <label>Branch Address</label>
                  <Input
                    style={{ width: "100%" }}
                    value={element.address}
                    placeholder="Branch Address"
                  />
                  <div className="validate" />
                </div>
                <div className="col-lg-4 col-md-6">
                  <span className="text-danger">*</span> <label>Tagline</label>
                  <Input
                    style={{ width: "100%" }}
                    value={element.tagline}
                    placeholder="tagline"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-4 col-md-6">
                  <span className="text-danger">*</span>{" "}
                  <label>Contact Person Name</label>
                  <Input
                    style={{ width: "100%" }}
                    value={element.contactPersonName}
                    placeholder="Contact Person Name"
                  />
                </div>
                <div className="col-lg-4 col-md-6">
                  <span className="text-danger">*</span>{" "}
                  <label>Contact Person Contact</label>
                  <Input
                    style={{ width: "100%" }}
                    value={element.contactPersonContact}
                    placeholder="Contact Person Contact"
                  />
                  <div className="validate" />
                </div>
                <div className="col-lg-4 col-md-6">
                  <span className="text-danger">*</span>{" "}
                  <label>Contact Person Email</label>
                  <Input
                    style={{ width: "100%" }}
                    value={element.contactPersonEmail}
                    placeholder="Contact Person "
                  />
                  <div className="validate" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div>
                    <Radio checked={whFlag === 1} onChange={() => setwhFlag(1)}>
                      Open With main hours
                    </Radio>
                    <p>Show when your business is open</p>
                  </div>
                </div>
                {/* <div className='col-md-6'>

                                                    <div>
                                                        <Radio checked={whFlag == 2} onChange={() => setwhFlag(2)}>Open With No main hours</Radio>
                                                        <p>Don't show any business hour</p>
                                                    </div>

                                                </div> */}
                <div className="col-md-6">
                  <div>
                    <Radio checked={whFlag === 3} onChange={() => setwhFlag(3)}>
                      Temparly Closed{" "}
                    </Radio>
                    <p>Show that your business will be open again</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <Radio checked={whFlag === 4} onChange={() => setwhFlag(4)}>
                      Permant Closed
                    </Radio>
                    <p>Show that your business is no longer exist</p>
                  </div>
                </div>
              </div>
              <div className="days-section">
                <div>
                  {element.workingHour.map((item: any, index: any) => {
                    return (
                      <>
                        <b>
                          {item.day === 1
                            ? "Monday"
                            : item.day === 2
                            ? "Tuesday"
                            : item.day === 3
                            ? "Wednesday"
                            : item.day === 4
                            ? "Thursday"
                            : item.day === 5
                            ? "Friday"
                            : item.day === 6
                            ? "Saturday"
                            : item.day === 7
                            ? "Sunday"
                            : ""}
                        </b>
                        <div
                          className="d-flex justify-content-between"
                          key={index}
                        >
                          <div className="d-flex align-items-center">
                            <Checkbox name="isOpen" value={item.isOpen}>
                              Closed
                            </Checkbox>
                          </div>
                          <span>
                            <label>Opens At</label>
                            <p>
                              {" "}
                              <TimePicker
                                name="startTime"
                                size="small"
                                defaultValue={dayjs("12:08", format)}
                                format={format}
                              />
                            </p>
                          </span>
                          <span>
                            <label>Closes At</label>
                            <p>
                              {" "}
                              <TimePicker
                                name="endTime"
                                size="small"
                                defaultValue={dayjs("12:08", format)}
                                format={format}
                              />
                            </p>
                          </span>
                          {/* <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValue(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForMon.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteData(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div> */}
                        </div>
                        <hr></hr>
                      </>
                    );
                  })}
                </div>

                {/* <div>
                                                        Tuesday
                                                        {workingHrForTues.map((item: any, index: any) => {
                                                            return (
                                                                <div className='d-flex justify-content-between' key={index}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox name='isOpen'>Closed</Checkbox>
                                                                    </div>
                                                                    <span>
                                                                        <label>Opens At</label>
                                                                        <p> <TimePicker name='startTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <span>
                                                                        <label>Closes At</label>
                                                                        <p> <TimePicker name='endTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValueTues(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForTues.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteDataTues(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                    <div>
                                                        Wednesday
                                                        {workingHrForWed.map((item: any, index: any) => {
                                                            return (
                                                                <div className='d-flex justify-content-between' key={index}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox name='isOpen'>Closed</Checkbox>
                                                                    </div>
                                                                    <span>
                                                                        <label>Opens At</label>
                                                                        <p> <TimePicker name='startTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <span>
                                                                        <label>Closes At</label>
                                                                        <p> <TimePicker name='endTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValueWed(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForWed.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteDataWed(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                    <div>
                                                        Thursday
                                                        {workingHrForMon.map((item: any, index: any) => {
                                                            return (
                                                                <div className='d-flex justify-content-between' key={index}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox name='isOpen'>Closed</Checkbox>
                                                                    </div>
                                                                    <span>
                                                                        <label>Opens At</label>
                                                                        <p> <TimePicker name='startTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <span>
                                                                        <label>Closes At</label>
                                                                        <p> <TimePicker name='endTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValue(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForMon.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteData(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                    <div>
                                                        Friday
                                                        {workingHrForMon.map((item: any, index: any) => {
                                                            return (
                                                                <div className='d-flex justify-content-between' key={index}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox name='isOpen'>Closed</Checkbox>
                                                                    </div>
                                                                    <span>
                                                                        <label>Opens At</label>
                                                                        <p> <TimePicker name='startTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <span>
                                                                        <label>Closes At</label>
                                                                        <p> <TimePicker name='endTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValue(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForMon.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteData(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    <div>
                                                        Saturday
                                                        {workingHrForMon.map((item: any, index: any) => {
                                                            return (
                                                                <div className='d-flex justify-content-between' key={index}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox name='isOpen'>Closed</Checkbox>
                                                                    </div>
                                                                    <span>
                                                                        <label>Opens At</label>
                                                                        <p> <TimePicker name='startTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <span>
                                                                        <label>Closes At</label>
                                                                        <p> <TimePicker name='endTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValue(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForMon.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteData(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    <div>
                                                        Sunday
                                                        {workingHrForMon.map((item: any, index: any) => {
                                                            return (
                                                                <div className='d-flex justify-content-between' key={index}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox name='isOpen'>Closed</Checkbox>
                                                                    </div>
                                                                    <span>
                                                                        <label>Opens At</label>
                                                                        <p> <TimePicker name='startTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <span>
                                                                        <label>Closes At</label>
                                                                        <p> <TimePicker name='endTime' size='small' defaultValue={dayjs('12:08', format)} format={format} /></p>
                                                                    </span>
                                                                    <div className="d-flex align-items-center">
                                                                        <PlusOutlined onClick={() => AddMondayValue(item, index)} style={{ fontSize: '150%' }} />
                                                                        {workingHrForMon.length > 1 && <span style={{ marginLeft: '12px' }}>
                                                                            <DeleteOutlined onClick={() => deleteData(item.id)} style={{ fontSize: '150%' }} />
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
