import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';

// Define a type for the slice state
interface dishLitState {
    dishList: any[]
  }

  // Define the initial state using that type
const initialState: dishLitState = {
  dishList: [],
}
export const dishListSlice = createSlice({
  name: 'dishList',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
  
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateList: (state, action: PayloadAction<any>) => {
      state.dishList = action.payload
    }
  },
})
export const { updateList } = dishListSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectdishList = (state: RootState) => state.dishList

export default dishListSlice.reducer



