import React, { useEffect, useState } from "react";
import { useLocation} from "react-router-dom";
import './footer.css'
// import { RestaurantDetailInterface } from "../restaurant-list/interFace";
import api from "../../api";
import { BranchDetailsInterface } from "./BranchDetailsInterface";
export default function Footer() {
  const { pathname } = useLocation();
  const [isShowWorkingHours, setIsShowWorkingHours] = useState(false);
  console.log("path is " + pathname);


  const [branchDetails, setBranchDetails] =
    useState<BranchDetailsInterface>();


  const fetchBranchDetailData = async (restaurantId: string, branchId: string) => {
    try {
      await api
        .get(
          "/api/identity/restaurant-branch/" +
          branchId +
          "?restaurantId=" +
          restaurantId
        )
        .then(function (response) {

          console.log("sucess respp get branch details");
          console.log(response.data);

          setBranchDetails(response.data.data);

          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");

          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    if (pathname.startsWith("/restaurant")) {
      setIsShowWorkingHours(true);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      // const menuId = params.get('a');
      const branchId = params.get('b');
      const restaurantId = params.get('c');
      if (restaurantId) {
        fetchBranchDetailData(restaurantId, branchId);
      }

    } else {
      setIsShowWorkingHours(false);
    }
  }, [pathname]);


  // you can check a more conditions here
  if (
    pathname === "/sidebar" ||
    pathname === "/menu" ||
    pathname === "/dish" ||
    pathname === "/restaurants" ||
    pathname === "/profile" ||
    pathname === "/theme" ||
    pathname === "/admin-panel" ||
    pathname === "/restaurant-theme" ||
    pathname === "/category" ||
    pathname === "/branch-menu" ||
    pathname === "/branches" ||
    pathname === "/menu-creation" ||
    pathname === "/menu-view" ||
    pathname === "/restaurants-info" ||
    pathname === "/document-upload" ||
    pathname === "/menu-creation" ||
    pathname === "/today-combo-offers" ||
    pathname === "/today-discounted-offers" ||
    pathname === "/upcoming-offers" ||
    pathname === "/restaurant-special" ||
    pathname === "/todays-special" ||
    pathname === "/event-meal" ||
    pathname === "/buffet-meal" ||
    pathname === "/users" ||
    pathname === "/subscription-plan" ||
    pathname === "/subscription-info" ||
    pathname === "/change-password" ||
    pathname.startsWith("/menu-creation") ||
    pathname.startsWith("/buffet-menu-creation") ||
    pathname.startsWith("/menu-view")  ||
    pathname.startsWith("/buffet-menu-view")
  )
    return null;


  return (
    <>
      {pathname.startsWith("/restaurant") ? (
        <footer id="footer" className="footer">
          <div className="container footer-line">
            <div className="row gy-3">
              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-telephone icon" />
                <div>
                  <h4>Contact Us</h4>
                  <p>
                  {branchDetails && branchDetails.contactNumber && (
                      <a href={`tel:+971 50724 5494`} className="a-tag">
                        {branchDetails.contactNumber}
                      </a>
                    )}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-envelope-at icon" />
                <div>
                  <h4>Email Us</h4>
                  <p>
                  {branchDetails && branchDetails.email && (
                    <a href="mailto:sales@innovatechsoft.com" className="a-tag">{branchDetails.email|| 'N/A'}</a>
                  )}
                    
                  </p>

                </div>
              </div>
              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-geo-alt-fill icon" />
                <div>
                  <h4>Address</h4>
                  <p>
                  {branchDetails && branchDetails.address && (
                    <a href="mailto:sales@innovatechsoft.com" className="a-tag">{branchDetails.address|| 'N/A'}</a>
                  )}
                   
                  </p>

                </div>
              </div>
              
            </div>
            {isShowWorkingHours ? (
              <div style={{ margin: "10px 0px" }}>
                <hr />
                <h4>Working hours</h4>
                <div className="row">
                  {branchDetails?.workingHour !== null && Array.isArray(branchDetails?.workingHour) &&
                    (branchDetails?.workingHour as any[]).map(
                      (item, index) => (
                        <div key={index} className="col-lg-3 col-md-3">
                          <div>
                            {item.day === 1
                              ? <strong>Monday</strong>
                              : item.day === 2
                                ? <strong>Tuesday</strong>
                                : item.day === 3
                                  ? <strong>Wednesday</strong>
                                  : item.day === 4
                                    ? <strong>Thursday</strong>
                                    : item.day === 5
                                      ? <strong>Friday</strong>
                                      : item.day === 6
                                        ? <strong>Saturday</strong>
                                        : item.day === 7
                                          ? <strong>Sunday</strong>
                                          : ""}
                          </div>
                          {/* <div>Opening : {item.startTime}  Closing : {item.endTime} ( {!item.isOpen ? (
                            <span>Open</span>
                           ) : (
                            <span>Closed</span>
                          )})</div> */}
                          
                          {!item.isOpen ? (
                                      <>
                                        <span>Opening time: {item.startTime} Closing time: {item.endTime}</span>
                                        <span> (Open)</span>
                                        </>
                                          

                                    ) : (<span> (Closed)</span>

                                    )}
                          <br className="transperent-hr" />
                        </div>
                      )
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="container">
            <div className="copyright">
              Powered by{" "}
              <strong>
                <span><a href="https://www.innovatechsoft.com/" className="a-tag">INNOVATECH SOFTWARE SOLUTIONS</a></span>
              </strong>
              . All Rights Reserved
              <div className="footer-links">
                <div className="social-links d-flex justify-content-center">
                  <a href="https://twitter.com/Innovatechsoft" className="twitter">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="https://www.facebook.com/people/InnovaTech-Software-Solution/61556855141266/" className="facebook">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="https://www.instagram.com/innovatech_software_solution/" className="instagram">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/innovatech-software-solution/" className="linkedin">
                    <i className="bi bi-linkedin" />
                  </a>
                  <a href="https://www.tiktok.com/@innovatech_software" className="linkedin">
                    <i className="bi bi-tiktok" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer
          id="footer"
          className="footer"
          style={{ backgroundColor: "#302730" }}
        >
          <div className="container footer-line">
            <div className="row gy-3">
              <div className="col-lg-4 col-md-4 d-flex">
              <i className="bi bi-telephone icon" />
                <div>
                  <h4>Contact Us</h4>
                  <p>
                    <a href="tel:+971 50724 5494" className="a-tag">+971 50724 5494</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-envelope-at icon" />
                <div>
                  <h4>Email Us</h4>
                  <p>
                    <a href="mailto:sales@innovatechsoft.com" className="a-tag">sales@innovatechsoft.com</a>
                  </p>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-4">
                <h4>Follow Us</h4>
                <div className="social-links d-flex">
                  <a href="#" className="twitter">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="#" className="facebook">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="#" className="instagram">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="#" className="linkedin">
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-3 d-flex">
                 <i className="bi bi-calendar-check icon" />
                <div>
                  <h4>Working hours</h4>
                  <p className="a-tag">
                    Mon 7 AM - 12 PM
                  </p>
                  <p className="a-tag">
                    Sunday Closed
                  </p>
                </div> 
              </div> */}
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              Powered by{" "}
              <strong>
                <span><a href="https://www.innovatechsoft.com/" className="a-tag" target="_blank" rel="noopener noreferrer">INNOVATECH SOFTWARE SOLUTIONS</a> </span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="footer-links">
              <div className="social-links d-flex justify-content-center">
                <a href="https://twitter.com/Innovatechsoft" className="twitter">
                  <i className="bi bi-twitter" />
                </a>
                <a href="https://www.facebook.com/people/InnovaTech-Software-Solution/61556855141266/" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://www.instagram.com/innovatech_software_solution/" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/innovatech-software-solution/" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
                <a href="https://www.tiktok.com/@innovatech_software" className="linkedin">
                  <i className="bi bi-tiktok" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}z
    </>
  );
}
