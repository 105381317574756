import React from 'react';
import Select from 'react-select';

const SearchableDropdown = ({ name,options, onChange }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '47px', // Set the minimum height
      height: '47px', // Set the fixed height
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '47px', // Match the container height
      display: 'flex',
      alignItems: 'center', // Center the content vertically
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px', // Remove any extra margin
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '47px', // Match the container height
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '160px', // Set the maximum height of the dropdown menu
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '160px', // Set the maximum height of the dropdown menu list
    }),
    option: (provided) => ({
      ...provided,
      padding: '5px 10px', // Adjust padding to reduce height of individual options
    }),
  };
  
  return (
    <Select
    name={name} 
      options={options}
      onChange={onChange}
      isSearchable
      styles={customStyles}

    />
  );
};

export default SearchableDropdown;
